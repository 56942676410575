import { Theme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { SimpleTooltip } from './Tooltips';
import featureFlags from '../../../feature-flags.json';
import { useAuthentication } from 'src/features/authentication/context';
import { ACCESS_ROLES } from 'src/config';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  onSort?: (id: string) => void;
  sx?: SxProps<Theme>;
};

export const AddGatewayTableHead = ({ order, orderBy, headLabel, onSort, sx }: Props) => {
  const {
    user,
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const renderHeadCell = (headCell: any) => {
    const hasTooltip = headCell.id === 'hide' || headCell.id === 'controllable';
    const headCellElement = (
      <span>
        <TableSortLabel
          hideSortIcon
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={() => onSort?.(headCell.id)}
          sx={{ textTransform: 'capitalize' }}
          data-sm={headCell.testId}
        >
          <Typography variant="inherit" sx={{ marginRight: hasTooltip ? '4px' : 0 }}>
            {headCell.label}
          </Typography>

          {orderBy === headCell.id ? (
            <Box sx={{ ...visuallyHidden }}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          ) : null}
          {headCell.id === 'hide' ? (
            <SimpleTooltip content="Select this option to hide a tag from being displayed as an option when selecting data in the app (i.e. widgets)" />
          ) : null}
          {headCell.id === 'controllable' ? (
            <SimpleTooltip content="Values of controllable tags can be modified in the Control Widget under Site Overview" />
          ) : null}
        </TableSortLabel>
      </span>
    );

    return headCellElement;
  };
  return (
    <TableHead data-sm="table-headers" sx={sx}>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width:
                featureFlags.advancedTagMapping === 1 ||
                featureFlags.userIds.includes(user.userId) ||
                getCurrentRole(customerId, siteId) === ACCESS_ROLES.SUPER_ADMIN
                  ? 'auto'
                  : '24%',
            }}
          >
            {renderHeadCell(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
