import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { StopAlaramIcon, CheckmarkCircleFilledLargeIcon } from 'src/assets';
import { StateLabelOnBox, StateLabelOffBox } from 'src/components';
import { TagType } from '../../types/types';
import { StyledItemText, StyledTagItem } from './style';
import { LabelTagBox } from '../label-tag-box';

type Props = {
  tag: TagType;
  isMobile: boolean;
  testId: string;
  liveDataComplete: any;
};
export const TagItem = ({ tag, isMobile, testId, liveDataComplete }: Props) => {
  const liveDataTag = liveDataComplete?.tags?.find((item: any) => item.id === tag.tagId);

  const theme = useTheme();
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.main;
  const tagValue = Number(liveDataTag?.value || tag?.value);
  const displayName = liveDataTag?.displayName || tag?.displayName;
  const rawUnit = liveDataTag?.rawUnit || tag?.rawUnit;
  const displayedRawUnit = rawUnit && `(${rawUnit})`;

  //State tag
  const stateTag =
    liveDataTag?.connectedToAssets?.find((item: any) => item.assignedTagLabel === 'state') ??
    tag?.connectedToAssets?.find((item) => item.assignedTagLabel === 'state');
  //Alarm tag
  const alarmTag = liveDataTag?.dataType === 'alarm' || tag?.dataType === 'alarm';
  //Alarm priority tag
  const alarmPriorityTag = liveDataTag?.alarm || tag?.alarm;

  //State tag mapped to more assets
  const lengthOfConnectedAssetLiveTag = liveDataTag?.connectedToAssets?.length > 1;
  const lengthOfConnectedAssetTag = tag?.connectedToAssets?.length > 1;
  const isMoreAssets =
    (lengthOfConnectedAssetLiveTag &&
      liveDataTag?.connectedToAssets?.some((item: any) => item.assignedTagLabel !== 'state')) ||
    (lengthOfConnectedAssetTag &&
      tag?.connectedToAssets?.some((item: any) => item.assignedTagLabel !== 'state'));

  const isStateTagWithMoreDifferentAssets = stateTag && isMoreAssets;

  const numberOfAllowedCaracters = isMobile ? 18 : 35;

  const displayNameAndRawUnit = `${displayName}${displayedRawUnit ? `${displayedRawUnit}` : ''}`;
  const displayNameLength = displayNameAndRawUnit?.length;

  const notRestOfTheTags = !stateTag && !alarmTag && !alarmPriorityTag;

  return (
    <StyledTagItem theme={theme} isMobile={isMobile} data-testid={testId}>
      <Tooltip
        title={displayNameAndRawUnit}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#fff',
            },
          },
        }}
      >
        <StyledItemText isMobile={isMobile}>
          {displayNameAndRawUnit.slice(0, numberOfAllowedCaracters)}
          {displayNameLength > numberOfAllowedCaracters && (
            <Typography component="span">... </Typography>
          )}
        </StyledItemText>
      </Tooltip>
      {!isStateTagWithMoreDifferentAssets && stateTag && tagValue === 1 ? (
        <StateLabelOnBox />
      ) : null}
      {!isStateTagWithMoreDifferentAssets && stateTag && tagValue === 0 ? (
        <StateLabelOffBox />
      ) : null}
      {!stateTag && alarmTag && tagValue === 0 ? (
        <Box>
          <CheckmarkCircleFilledLargeIcon fill={greenColor} width={16} height={16} />
        </Box>
      ) : null}
      {!stateTag && alarmTag && tagValue === 1 ? (
        <StopAlaramIcon width={16} height={16} fill={redColor} />
      ) : null}
      {!stateTag && !alarmTag && alarmPriorityTag && (
        <LabelTagBox alarmPriority={alarmPriorityTag} value={tagValue} />
      )}
      {notRestOfTheTags || isStateTagWithMoreDifferentAssets ? (
        <LabelTagBox alarmPriority="null" value={tagValue} />
      ) : null}
    </StyledTagItem>
  );
};
