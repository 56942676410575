import { TableRow, TableCell, IconButton } from '@mui/material';

import { EditIcon, EyeIcon } from 'src/assets';
import { DataSource } from '../types';
import { Label, LabelColor } from 'src/components';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useAuthentication } from 'src/features/authentication/context';

type Props = {
  row: DataSource;
  selected: boolean;
  onSelectRow: VoidFunction;
  handleEdit: VoidFunction;
};

export const DataSourcesTableRow = ({ row, selected, onSelectRow, handleEdit }: Props) => {
  const { id, dataSourceName, dataSourceType, dataSourceStatus } = row;

  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const handleLabelColor = (status: string): LabelColor => {
    switch (status) {
      case 'active':
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <>
      <TableRow hover selected={selected} data-sm="table-row">
        <TableCell data-sm="data-source-id-row" align="left">
          {id}
        </TableCell>

        <TableCell data-sm="name-row" align="left">
          {dataSourceName}
        </TableCell>

        <TableCell data-sm="data-source-type-row" align="left">
          {dataSourceType.replace(/_/g, ' ').toUpperCase()}
        </TableCell>

        <TableCell data-sm="status-row" align="left">
          <Label
            variant="filled"
            color={handleLabelColor(dataSourceStatus!)}
            sx={{
              textTransform: 'capitalize',
              color:
                dataSourceStatus === 'active' ||
                dataSourceStatus === 'deactivated' ||
                dataSourceStatus === null
                  ? 'white'
                  : '#637381',
              fontWeight: 700,
            }}
          >
            {dataSourceStatus ? dataSourceStatus : 'Active'}
          </Label>
        </TableCell>

        <TableCell data-sm="context-menu-row" align="right">
          <IconButton onClick={handleEdit}>
            {getCurrentRole(customerId, siteId) === AccessRoles.SITE_EMPLOYEE ? (
              <EyeIcon width={24} height={24} />
            ) : (
              <EditIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
