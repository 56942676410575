import ThemeProvider from './features/theme';
import { AuthenticationProvider } from './features/authentication/context/authentication.context';
import { SnackbarProvider } from './components/minimals/snackbar/SnackbarProvider';
import { SiteProvider } from './contexts/siteContext/sites.context';
import { ThemeTypeProvider } from './contexts/themeTypeContext/theme-type-context';
import { TagUpdateAlertsProvider } from './contexts/tagUpdateAlertsContext';
import { SiteMapFilterProvider } from './contexts/siteMapFilterContext';
import { AlarmsProvider } from './contexts/alarmsContext';
import { CustomerProvider } from './contexts/customerContext/customer.context';
import { CustomerAssetProvider } from './contexts/assetContext/asset.context';
import App from './App';

export default function Main() {
  return (
    <CustomerProvider>
      <AlarmsProvider>
        <CustomerAssetProvider>
          <ThemeTypeProvider>
            <ThemeProvider>
              <AuthenticationProvider>
                <TagUpdateAlertsProvider>
                  <SiteProvider>
                    <SiteMapFilterProvider>
                      <SnackbarProvider>
                        <App />
                      </SnackbarProvider>
                    </SiteMapFilterProvider>
                  </SiteProvider>
                </TagUpdateAlertsProvider>
              </AuthenticationProvider>
            </ThemeProvider>
          </ThemeTypeProvider>
        </CustomerAssetProvider>
      </AlarmsProvider>
    </CustomerProvider>
  );
}
