import { READINGS, api } from '../api';
import {
  GetAllParams,
  GetAllParamsChunkedData,
  GetAllReadingItem,
  GetAllResponse,
  GetLatestReadingsNewDataStorageTypes,
  GetReadingsNewDataStorageTypes,
} from './types';
import * as pako from 'pako';

const CHUNK_SIZE = 50;

const getAll = async (params: GetAllParams): Promise<GetAllResponse> => {
  let startRange = 0;
  let endRange = startRange + CHUNK_SIZE - 1; // -1 to make the range inclusive
  let allItems: GetAllReadingItem[] = [];

  while (true) {
    const data = await fetchDataInChunks({
      ...params,
      startRange,
      endRange,
    });

    //console.log(data, data.items, 'DATA ITEMS');

    if (!data.items || data.items.length === 0) {
      //console.log('BREAK');
      break;
    }

    if (params.limit && params.limit <= allItems.length) {
      break;
    }

    allItems.push(...data.items);
    //console.log(allItems, 'allItems');

    if (!data.lastEvaluatedKey) {
      break;
    }

    startRange = data.lastEvaluatedKey;
    endRange = 500;
  }

  return { items: allItems };
};

const fetchDataInChunks = async (chunkParams: GetAllParamsChunkedData) => {
  const { customerId, siteId, gatewayId, limit, fromDate, toDate, skipTo, startRange, endRange } =
    chunkParams;

  const queryParams = [
    limit && `limit=${limit}`,
    fromDate && `fromDate=${fromDate}`,
    toDate && `toDate=${toDate}`,
    skipTo && `skipTo=${skipTo}`,
  ]
    .filter(Boolean)
    .join('&');

  const url = `${READINGS}/${customerId}/site/${siteId}${READINGS}/${gatewayId}?${queryParams}`;

  const headers = {
    Range: `bytes=${startRange}-${endRange}`,
  };

  try {
    const response = await api.get(url, {
      headers,
      responseType: 'arraybuffer',
      transformResponse: [
        (data: ArrayBuffer) => {
          const decompressedData = pako.inflate(new Uint8Array(data), { to: 'string' });
          return JSON.parse(decompressedData);
        },
      ],
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return { items: [] };
  }
};

const getLatestReadings = async (customerId: string, gatewayId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `${READINGS}/${customerId}/site/${siteId}${READINGS}/${gatewayId}/latest`,
  });

  return response.data;
};

const getReadingsNewDataStorage = async ({
  gatewayId,
  startDate,
  endDate,
  measurements,
  aggregationType,
  statistics,
}: GetReadingsNewDataStorageTypes) => {
  const queryParams = [
    startDate && `start_date=${startDate}`,
    endDate && `end_date=${endDate}`,
    measurements && `measurements=${measurements}`,
    aggregationType && `aggregation_type=${aggregationType}`,
    statistics && `statistics=${statistics}`,
  ]
    .filter(Boolean)
    .join('&');

  const response = await api({
    method: 'get',
    url: `v2/data/${gatewayId}/trends?${queryParams}`,
  });

  return response.data;
};

const getLatestReadingsNewDataStorage = async ({
  gatewayId,
  measurements,
  aggregationType,
}: GetLatestReadingsNewDataStorageTypes) => {
  const queryParams = [
    measurements && `measurements=${measurements}`,
    aggregationType && `aggregation_type=${aggregationType}`,
  ]
    .filter(Boolean)
    .join('&');

  const response = await api({
    method: 'get',
    url: `v2/data/${gatewayId}/trends/latest?${queryParams}`,
  });

  return response.data;
};

const getAllResponseReadings = async (params: GetAllParams): Promise<GetAllReadingItem[]> => {
  const readings = [];
  let newLastEvaluatedKey = null;
  do {
    const { items, lastEvaluatedKey } = await getAllReadingsV2(params);
    newLastEvaluatedKey = lastEvaluatedKey;
    params.skipTo = lastEvaluatedKey?.toString();
    readings.push(...items);
  } while (newLastEvaluatedKey);

  return readings;
};

const getAllReadingsV2 = async (params: GetAllParams): Promise<GetAllResponse> => {
  const { customerId, siteId, gatewayId, limit, fromDate, toDate, skipTo } = params;

  const queryParams = [
    limit && `limit=${limit}`,
    fromDate && `fromDate=${fromDate}`,
    toDate && `toDate=${toDate}`,
    skipTo && `skipTo=${skipTo}`,
  ]
    .filter(Boolean)
    .join('&');

  const url = `${READINGS}/${customerId}/site/${siteId}${READINGS}/${gatewayId}?${queryParams}`;

  const response = await api.get(url, {
    responseType: 'arraybuffer',
    transformResponse: [
      (data: ArrayBuffer) => {
        const decompressedData = pako.inflate(new Uint8Array(data), { to: 'string' });
        return JSON.parse(decompressedData);
      },
    ],
  });

  return response.data;
};

export const ReadingsService = {
  getAll,
  getLatestReadings,
  getReadingsNewDataStorage,
  getLatestReadingsNewDataStorage,
  getAllResponseReadings,
};
