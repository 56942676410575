import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomerService } from './services';
import { CustomerAccount } from './features/customer-accounts/types/customer-account';
import { ThemeService } from './services/themes';
import { LOGIN_PATH, ROOT_PATH } from './routes/config';

const Favicon = () => {
  const params = new URLSearchParams(window.location.search);
  const location = useLocation();
  const customerID = params.get('customerId');
  const validCustomerId = customerID ?? '';
  const defaultPath = '/favicon/favicon-32x32.png';
  const filename = 'favicon.png';

  const [faviconUrl, setFaviconUrl] = useState<string>(defaultPath);

  useLayoutEffect(() => {
    if (location.pathname === ROOT_PATH || location.pathname === LOGIN_PATH) {
      setFaviconUrl(defaultPath);
    } else if (customerID) {
      CustomerService.getById(customerID).then((data: CustomerAccount) => {
        if (data.selectedThemeId) {
          ThemeService.getLogo(validCustomerId, filename, data.selectedThemeId).then((data) => {
            setFaviconUrl(data);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, validCustomerId]);

  useLayoutEffect(() => {
    const updateFavicon = () => {
      const favicon = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
      if (favicon) {
        favicon.href = faviconUrl;
      } else {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = faviconUrl;
        document.head.appendChild(newFavicon);
      }
    };

    updateFavicon();
  }, [faviconUrl]);

  return null;
};

export default Favicon;
