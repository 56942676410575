import { Suspense, lazy, ElementType } from 'react';
import { Box } from '@mui/material';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<Box component={'div'} />}>
      <Component {...props} />
    </Suspense>
  );

export const CustomerAccountManagementPage = Loadable(
  lazy(() => import('src/features/customer-accounts/pages/CustomerAccountManagement'))
);
export const AddCustomerAccountPage = Loadable(
  lazy(() => import('src/features/customer-account-details/pages/AddCustomerAccount'))
);
export const EditCustomerAccountPage = Loadable(
  lazy(() => import('src/features/customer-account-details/pages/EditCustomerAccount'))
);
export const LoginPage = Loadable(lazy(() => import('src/features/login/pages/Login')));

export const UserAccountManagementPage = Loadable(
  lazy(() => import('src/features/user-accounts/pages/UserAccountManagement'))
);

export const InviteUserAccountPage = Loadable(
  lazy(() => import('src/features/user-account-details/pages/InviteUserAccount'))
);

export const EditUserAccountPage = Loadable(
  lazy(() => import('src/features/user-account-details/pages/EditUserAccount'))
);

export const SiteManagementPage = Loadable(
  lazy(() => import('src/features/sites/pages/site-management/SiteManagement'))
);

export const SiteMapPage = Loadable(
  lazy(() => import('src/features/sites/pages/site-map/SiteMap'))
);

export const SiteDashboardPage = Loadable(
  lazy(() => import('src/features/sites/pages/site-dashboard/SiteDashboard'))
);

export const AddGatewayPage = Loadable(
  lazy(() => import('src/features/gateway-details/pages/AddGateway'))
);

export const ForgotPasswordPage = Loadable(
  lazy(() => import('src/features/forgot-password/pages/ForgotPassword'))
);

export const LinkSentPage = Loadable(
  lazy(() => import('src/features/forgot-password/pages/LinkSent'))
);

export const AddSitePage = Loadable(lazy(() => import('src/features/sites/pages/AddSite')));

export const GatewayManagementPage = Loadable(
  lazy(() => import('src/features/gateways/pages/GatewayManagement'))
);

export const EditSitePage = Loadable(lazy(() => import('src/features/sites/pages/EditSite')));

export const AddAssetPage = Loadable(
  lazy(() => import('src/features/asset-details/pages/AddAsset'))
);

export const EditAssetPage = Loadable(
  lazy(() => import('src/features/asset-details/pages/EditAsset'))
);

export const EditGatewayPage = Loadable(
  lazy(() => import('src/features/gateway-details/pages/EditGateway'))
);

export const AssetsManagementPage = Loadable(
  lazy(() => import('src/features/assets/pages/AssetsManagement'))
);

export const ReportsPage = Loadable(lazy(() => import('src/features/reports/pages/Reports')));

export const ReportsPageNew = Loadable(lazy(() => import('src/features/reports/pages/ReportsNew')));

export const AlarmRulesManagementPage = Loadable(
  lazy(() => import('src/features/alarm-rules/pages/AlarmRulesManagement'))
);

export const AddAlarmRulePage = Loadable(
  lazy(() => import('src/features/alarm-rules-details/pages/AddAlarmRule'))
);

export const EditAlarmRulePage = Loadable(
  lazy(() => import('src/features/alarm-rules-details/pages/EditAlarmRule'))
);

export const AlarmsPage = Loadable(
  lazy(() => import('src/features/alarms/pages/alarm-management/AlarmManagement'))
);

export const AlarmsNotificationPage = Loadable(
  lazy(
    () =>
      import(
        'src/features/alarms/pages/alarms-notification-management/AlarmsNotificationManagement'
      )
  )
);

export const TenantSwitchPage = Loadable(
  lazy(() => import('src/features/tenant-switch/pages/TenantSwitch'))
);

export const AccountIssuePage = Loadable(
  lazy(() => import('src/features/tenant-switch/pages/AccountIssue'))
);

export const TrendsPageNew = Loadable(lazy(() => import('src/features/trends/pages/Trends')));

export const SetProfilePage = Loadable(
  lazy(() => import('src/features/set-profile/pages/SetProfile'))
);

export const ControllerManagementPage = Loadable(
  lazy(() => import('src/features/controllers/pages/ControllerManagement'))
);

export const AddControllerPage = Loadable(
  lazy(() => import('src/features/controllers-details/pages/AddController'))
);

export const EditControllerPage = Loadable(
  lazy(() => import('src/features/controllers-details/pages/EditController'))
);

export const UserProfilePage = Loadable(
  lazy(() => import('src/features/user-profile/pages/UserProfile'))
);
export const PageNotFoundPage = Loadable(
  lazy(() => import('src/features/page-not-found/PageNotFound'))
);

export const AddAlarmNotificationGroupPage = Loadable(
  lazy(
    () => import('src/features/alarms-notification-group-details/pages/AddAlarmNotificationPage')
  )
);

export const EditAlarmNotificationGroupPage = Loadable(
  lazy(
    () => import('src/features/alarms-notification-group-details/pages/EditAlarmNotificationPage')
  )
);

export const OneClickAlarmAcknowledgementPage = Loadable(
  lazy(
    () =>
      import(
        'src/features/alarms/pages/one-click-alarm-acknowledgement/OneClickAlarmAcknowledgement'
      )
  )
);
