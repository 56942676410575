import { Box, Typography } from '@mui/material';
import { WidgetWrapper } from '../WidgetWrapper';
import { cardPumpStatus, cardPumpImage, cardPumpMode } from '../style';
import { pump, StyledCardPumpStatus, modeValue } from './style';
import { PumpIcon, DosingPumpIcon } from 'src/assets/icons';
import { SubmersiblePumpWidget } from 'src/assets';
import { AlarmKind } from '../../types';
import { PUMP_1, PUMP_2, PUMP_3, PUMP_TAG_NAME } from 'src/features/sites';
import { AssetType } from 'src/features/sites/types/types';
import { useGetLatestGatewayReadings } from 'src/hooks';

type Props = {
  title: string;
  runMode: boolean | null;
  mode: string;
  alarm?: AlarmKind;
  isEditMode?: boolean;
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  widgetSubType?: string;
  asset: AssetType | undefined;
};

export const Pump = ({
  title,
  runMode,
  mode,
  isEditMode,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  widgetSubType,
  asset,
}: Props) => {
  const renderWidgetIcon = () => {
    const iconWidth = 70;
    const iconHeight = 70;
    if (widgetSubType === PUMP_1) return <PumpIcon width={90} height={90} />;
    if (widgetSubType === PUMP_2) return <DosingPumpIcon width={iconWidth} height={iconHeight} />;
    if (widgetSubType === PUMP_3)
      return <SubmersiblePumpWidget width={iconWidth} height={iconHeight} />;
    return <PumpIcon />;
  };

  const pumpAsset = asset?.assignedTags?.find((tag) => tag.tagName === PUMP_TAG_NAME);

  const assignedTagId = pumpAsset?.assignedTagId;
  const gatewayId = pumpAsset?.assignedGatewayId;

  const { readings: latestReadings, isDataReady } = useGetLatestGatewayReadings({
    gatewayId: gatewayId,
    tagId: assignedTagId,
  });
  const readingsRawInputName = assignedTagId && latestReadings[assignedTagId];
  const readingsValues = readingsRawInputName?.values;
  const readingValueList = readingsValues && Object.values(readingsValues);
  const readingValue = readingValueList && readingValueList[0];

  const toogleLabel = runMode || readingValue ? 'On' : 'Off';
  const isReady = runMode !== null && isDataReady;
  const isPumpActive = runMode || (readingValue as boolean);

  return (
    <WidgetWrapper
      title={title}
      isEditMode={isEditMode}
      widgetId={widgetId!}
      toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
      testId={testId}
      isDataReady={isReady}
      isPumpWidget={true}
    >
      <Box sx={pump}>
        <Box sx={cardPumpImage}>{renderWidgetIcon()}</Box>
        <Box sx={cardPumpStatus}>
          {isDataReady ? (
            <StyledCardPumpStatus isRunMode={!!isPumpActive} variant="contained">
              {toogleLabel}
            </StyledCardPumpStatus>
          ) : null}
        </Box>

        <Box sx={cardPumpMode}>
          {mode ? (
            <Box>
              <Typography>Mode: </Typography>
              <Typography sx={modeValue}>{mode}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default Pump;
