import { MenuPopover } from 'src/components';
import { MenuPopoverItem } from 'src/features/customer-accounts/components';
import { useHtmlElement } from 'src/hooks';
import {
  COPY_ADDRESS_DESCRIPTION,
  EDIT_SITE_PREVIEW,
  OPEN_IN_APPLE_MAPS_DESCRIPTION,
  OPEN_IN_GOOGLE_MAPS_DESCRIPTION,
} from 'src/features/sites/config';
import { DirectionsIcon } from 'src/assets/icons/DirectionsIcon';
import { CheckIcon } from 'src/assets/icons/CheckIcon';
import { useState } from 'react';
import { NewSite } from '../../types/sites';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { DuplicateCopyIcon, EditIcon, MoreVerticalFillIcon, MapPinIcon } from 'src/assets/icons';
import { getAddressString } from '../../utils/getAddressString';
import { Divider } from '@mui/material';
import { useAuthentication } from 'src/features/authentication/context';
import { ACCESS_ROLES } from 'src/config';

type GetDirectionsButtonProps = {
  row?: NewSite;
  handleEditSitePreview: (siteId: string | null) => void;
};

export const GetDirectionsButton = ({ row, handleEditSitePreview }: GetDirectionsButtonProps) => {
  const {
    getCurrentRole,
    siteId: { value: siteId },
    customerId: { value: customerId },
  } = useAuthentication();
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const theme = useTheme();
  const isSuperAdmin = getCurrentRole(customerId, siteId) === ACCESS_ROLES.SUPER_ADMIN;
  const isAccountAdmin = getCurrentRole(customerId, siteId) === ACCESS_ROLES.ACCOUNT_ADMIN;
  const addressString = getAddressString(row);

  const handleCopyAddressClick = (address: string) => {
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
  };

  const handleOpenInAppleMapsClick = (address: string) => {
    const url = `https://maps.apple.com/?q=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  };

  const handleOpenInGoogleMapsClick = (address: string) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <IconButton
        color={htmlElement ? 'inherit' : 'default'}
        sx={{ padding: '0' }}
        onClick={(event) => {
          event.stopPropagation();
          addHtmlElement(event.currentTarget);
        }}
        data-sm="context-menu-button"
      >
        <MoreVerticalFillIcon />
      </IconButton>
      <MenuPopover
        open={htmlElement}
        onClose={(event) => {
          removeHtmlElement();
          // timeout for 100ms bc of animation
          setTimeout(() => {
            setIsAddressCopied(false);
          }, 200);
        }}
        arrow="right-top"
        sx={{ width: 190, backgroundColor: theme.palette.background.default }}
      >
        {isAccountAdmin || isSuperAdmin ? (
          <>
            <MenuPopoverItem
              handleClick={(event: any) => {
                event.stopPropagation();
                handleEditSitePreview(row?.id ?? null);
                removeHtmlElement();
              }}
              description={EDIT_SITE_PREVIEW}
              icon={<EditIcon />}
            />

            <Divider />
          </>
        ) : null}

        <MenuPopoverItem
          color={theme.palette.grey[500]}
          description={addressString}
          icon={<MapPinIcon />}
          handleClick={(event: any) => {
            event.stopPropagation();
          }}
        />

        <MenuPopoverItem
          color={isAddressCopied ? theme.palette.success.main : 'black'}
          description={isAddressCopied ? 'Copied' : COPY_ADDRESS_DESCRIPTION}
          icon={isAddressCopied ? <CheckIcon /> : <DuplicateCopyIcon />}
          handleClick={(event) => {
            event.stopPropagation();
            handleCopyAddressClick(addressString);
          }}
        />

        <MenuPopoverItem
          handleClick={(event) => {
            event.stopPropagation();
            handleOpenInAppleMapsClick(addressString);
          }}
          description={OPEN_IN_APPLE_MAPS_DESCRIPTION}
          icon={<DirectionsIcon />}
        />

        <MenuPopoverItem
          icon={<DirectionsIcon />}
          handleClick={(event) => {
            event.stopPropagation();
            handleOpenInGoogleMapsClick(addressString);
          }}
          description={OPEN_IN_GOOGLE_MAPS_DESCRIPTION}
        />
      </MenuPopover>
    </>
  );
};
