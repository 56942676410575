import { PositioningTypes } from 'src/services';
import { AlarmPriority } from 'src/types/alarms';

export enum SiteType {
  lift_station = 'lift_station',
  water_treatment_plant = 'water_treatment_plant',
  wastewater_treatment_plant = 'wastewater_treatment_plant',
  booster_pump_station = 'booster_pump_station',
  water_tower = 'water_tower',
  process_plant = 'process_plant',
  combined_sewage_overflow = 'combined_sewage_overflow',
  water_well = 'water_well',
}

export type Location = {
  street?: string;
  city: string;
  country?: string;
  state: string;
  zip?: string;
  altitude?: string;
  longitude?: string;
  latitude?: string;
  timeZone?: string;
};

export enum WidgetDataTypeEnum {
  DAILY_CYCLE_COUNT = 'CYCLE_COUNT',
  RUNTIME = 'RUNTIME',
}

export enum WidgetTypeEnum {
  TOWER = 'WATER_TOWER',
  LIFT_STATION_WET_WELL = 'WET_WELL',
  PUMP = 'PUMP',
  BARS_GRAPH = 'BARS_GRAPH',
  SPIRAL_GRAPH = 'SPIRAL_GRAPH',
  LINEAR_GRAPH = 'LINEAR_GRAPH',
  SPIRAL_DOTS_GRAPH = 'SPIRAL_DOTS_GRAPH',
  CONTROLLER_WIDGET = 'CONTROLLER_WIDGET',
  VALVE = 'VALVE',
}

export type WidgetType = {
  connectedTo: string;
  createdAt: string;
  id: string;
  positioning: PositioningTypes;
  updatedAt: string;
  widgetType: WidgetTypeEnum;
  widgetSubType?: string;
  widgetDataType?: string;
};

type AssignedTag = {
  id: string;
  tagName: string;
  assignedTagId?: string;
  assignedGatewayId: string;
};

export type AssetProperty = {
  id?: string;
  name?: string;
  unit?: string;
  value?: string;
};

export type AssetType = {
  assetName: string;
  assetProperties?: AssetProperty[];
  assetType: string;
  assignedAlarmTags?: [];
  assignedTags?: AssignedTag[];
  createdAt: string;
  id: string;
  updatedAt: string;
};

export type DailyRuntime = {
  day: string;
  value: number;
};

export type DailyCycleCount = {
  day: string;
  value: number;
};

type ConnectedToAsset = {
  assetId: string;
  assetType: string;
  assignedTagLabel: string;
};

export type TagType = {
  alarm: AlarmPriority;
  connectedToAssets: ConnectedToAsset[];
  dataType: string | null;
  displayName: string;
  gatewayId: string;
  rawUnit: string | null;
  tagId: string;
  value: number | string | null;
  position: number;
};

export type TagsTypes = {
  siteId: string;
  tags: TagType[];
  showLiveData: boolean;
};
