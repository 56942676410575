import { Controller, ControllerAdditionalInfo, NewController } from 'src/types';
import {
  CONTROLLERS_URL,
  CONTROLLER_URL,
  SITES_ROOT,
  GATEWAYS_URL_END,
  api,
  READINGS,
} from '../api';
import { GetAllParamsTypes } from './types';

// SUMMARY:
// Endpoint for getting all controllers for specific site.
const getAll = async (
  customerId: string,
  siteId: string,
  params?: GetAllParamsTypes
): Promise<Controller[]> => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${CONTROLLERS_URL}?limit=1000`,
    params: params,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting all controllers for specific site.
const getCount = async (customerId: string, siteId: string): Promise<number> => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${CONTROLLERS_URL}/count`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting a specific controller
const getById = async (
  customerId: string,
  siteId: string,
  controllerId: string
): Promise<Controller> => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${CONTROLLERS_URL}/${controllerId}`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting additional information for a specific controller
const getAdditionalInfoById = async (
  customerId: string,
  siteId: string,
  gatewayId: string,
  controllerId: string
): Promise<ControllerAdditionalInfo[]> => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAYS_URL_END}/${gatewayId}${CONTROLLER_URL}/${controllerId}`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for creating a controller.
const add = async (data: NewController, customerId: string, siteId: string) => {
  const response = await api({
    method: 'post',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${CONTROLLERS_URL}`,
    data,
  });

  return response.data;
};

const edit = async (data: Controller, customerId: string, siteId: string) => {
  const response = await api({
    method: 'patch',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${CONTROLLERS_URL}/${data.id}`,
    data,
  });

  return response.data;
};

const updateInhand = async (data: any, siteId: string, customerId: string) => {
  const response = await api({
    method: 'post',
    url: `${READINGS}/${customerId}/site/${siteId}/controls/create`,
    data,
  });
  return response.data;
};

const getLatestControls = async (data: any, customerId: string, gatewayId: string) => {
  const response = await api({
    method: 'post',
    url: `${READINGS}/${customerId}/controls/latest/${gatewayId}`,
    data,
  });
  return response.data;
};

export const ControllersService = {
  getAll,
  getById,
  getAdditionalInfoById,
  getCount,
  add,
  edit,
  updateInhand,
  getLatestControls,
};
