import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const CloseIcon = ({ fill, width }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      style={{ width: `${width}px` }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-sm="close-icon"
    >
      <path
        d="M13.5307 0.709971C13.1407 0.319971 12.5107 0.319971 12.1207 0.709971L7.23068 5.58997L2.34068 0.699971C1.95068 0.309971 1.32068 0.309971 0.930684 0.699971C0.540684 1.08997 0.540684 1.71997 0.930684 2.10997L5.82068 6.99997L0.930684 11.89C0.540684 12.28 0.540684 12.91 0.930684 13.3C1.32068 13.69 1.95068 13.69 2.34068 13.3L7.23068 8.40997L12.1207 13.3C12.5107 13.69 13.1407 13.69 13.5307 13.3C13.9207 12.91 13.9207 12.28 13.5307 11.89L8.64068 6.99997L13.5307 2.10997C13.9107 1.72997 13.9107 1.08997 13.5307 0.709971Z"
        fill={color}
      />
    </svg>
  );
};
