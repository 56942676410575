import { TableContainer } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';

type StyledTableContainerType = {
  theme: Theme;
};

const customBreadcrumbs = {
  margin: '35px 0',
};

const exportBox = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '30px 0',
};

const StyledTableContainer = styled(TableContainer)(({ theme }: StyledTableContainerType) => ({
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.default,
}));

const calendarBox = {
  padding: '16px',
};

export { customBreadcrumbs, exportBox, StyledTableContainer, calendarBox };
