import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const Dashboard = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22" fill="none">
      <path
        d="M3.67741 11.9167H9.17741C9.68158 11.9167 10.0941 11.5042 10.0941 11V3.66667C10.0941 3.1625 9.68158 2.75 9.17741 2.75H3.67741C3.17324 2.75 2.76074 3.1625 2.76074 3.66667V11C2.76074 11.5042 3.17324 11.9167 3.67741 11.9167ZM3.67741 19.25H9.17741C9.68158 19.25 10.0941 18.8375 10.0941 18.3333V14.6667C10.0941 14.1625 9.68158 13.75 9.17741 13.75H3.67741C3.17324 13.75 2.76074 14.1625 2.76074 14.6667V18.3333C2.76074 18.8375 3.17324 19.25 3.67741 19.25ZM12.8441 19.25H18.3441C18.8482 19.25 19.2607 18.8375 19.2607 18.3333V11C19.2607 10.4958 18.8482 10.0833 18.3441 10.0833H12.8441C12.3399 10.0833 11.9274 10.4958 11.9274 11V18.3333C11.9274 18.8375 12.3399 19.25 12.8441 19.25ZM11.9274 3.66667V7.33333C11.9274 7.8375 12.3399 8.25 12.8441 8.25H18.3441C18.8482 8.25 19.2607 7.8375 19.2607 7.33333V3.66667C19.2607 3.1625 18.8482 2.75 18.3441 2.75H12.8441C12.3399 2.75 11.9274 3.1625 11.9274 3.66667Z"
        fill={color}
      />
    </svg>
  );
};
