import { CustomErrors } from 'src/types/errors';

export const addNewCustomError = (
  newMessage: string,
  name: string,
  customErrors: CustomErrors,
  setCustomErrors: (value: React.SetStateAction<CustomErrors>) => void,
  newExtendedMessage?: string
) => {
  let messagesNew: string[] = [];
  const messages = customErrors[name]?.message;
  if (messages && !messages.includes(newMessage)) {
    messages.push(newMessage);
  } else if (!messagesNew.includes(newMessage)) {
    messagesNew.push(newMessage);
  }

  let extendedMessagesNew: string[] = [];
  const extendedMessages = customErrors[name]?.extendedMessage;
  if (extendedMessages && newExtendedMessage && !extendedMessages.includes(newExtendedMessage)) {
    extendedMessages.push(newExtendedMessage);
  } else if (newExtendedMessage && !extendedMessagesNew.includes(newExtendedMessage)) {
    extendedMessagesNew.push(newExtendedMessage);
  }

  setCustomErrors((prevValue) => ({
    ...prevValue,
    [name]: {
      message: messages ?? messagesNew,
      extendedMessage: extendedMessages ?? extendedMessagesNew,
    },
  }));
};
