import { IconsTypesProps } from './types';
import { CURRENT_COLOR } from 'src/config';

export const WarningIcon = ({ fill }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.48184 18.0002H17.5418C19.0818 18.0002 20.0418 16.3302 19.2718 15.0002L11.7418 1.99018C10.9718 0.660176 9.05184 0.660176 8.28184 1.99018L0.75184 15.0002C-0.01816 16.3302 0.94184 18.0002 2.48184 18.0002ZM10.0118 11.0002C9.46184 11.0002 9.01184 10.5502 9.01184 10.0002V8.00018C9.01184 7.45018 9.46184 7.00018 10.0118 7.00018C10.5618 7.00018 11.0118 7.45018 11.0118 8.00018V10.0002C11.0118 10.5502 10.5618 11.0002 10.0118 11.0002ZM11.0118 15.0002H9.01184V13.0002H11.0118V15.0002Z"
        fill={color}
      />
    </svg>
  );
};
