import { SITES_ROOT, api } from '../api';
import { GetAllParamsTypes } from './types';

const add = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'post',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}/data/sources`,
  });

  return response.data;
};

const getAll = async (customerId: string, siteId: string, params?: GetAllParamsTypes) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}/data/sources`,
    params,
  });

  return response.data;
};

const getById = async (customerId: string, siteId: string, dataSourceId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}/data/sources/${dataSourceId}`,
  });

  return response.data;
};

const edit = async (data: any, customerId: string, siteId: string, dataSourceId: string) => {
  const response = await api({
    method: 'patch',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}/data/sources/${dataSourceId}`,
    data,
  });

  return response.data;
};

const getCount = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}/data/sources/count`,
  });

  return response.data;
};

export const DataSourceService = {
  add,
  getAll,
  getById,
  edit,
  getCount,
};
