import { useState, useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Checkbox,
  Stack,
  useTheme,
} from '@mui/material';
import { GetAllResponse } from 'src/services/sites/types';
import { ModalContentRow } from '../modal-content-row/ModalContentRow';
import { TagResponse } from 'src/features/alarm-rules/types';
import { StyledModalContentRow } from '../modal-content-row/style';
import { CustomWaterLoading } from 'src/components';
import { SitesService } from 'src/services/sites';
import { useAuthentication } from 'src/features/authentication/context';
import { ArrowDownIcon } from 'src/assets';

type ModalContentItemProps = {
  site: GetAllResponse;
  setValue: (name: string, value: string) => void;
  values: any;
  isParticularChart?: boolean;
  isTimelineChart?: boolean;
};

export const ModalContentItem = ({
  site,
  setValue,
  values,
  isParticularChart,
  isTimelineChart,
}: ModalContentItemProps) => {
  const [tagsForSpecificSite, setTagsForSpecificSite] = useState<TagResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const getData = () => {
    setLoading(true);
    try {
      SitesService.getTags(customerId!, site.id).then((tags) => setTagsForSpecificSite(tags));
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const newTags = useMemo(
    () => tagsForSpecificSite?.filter((tag: { hidden: boolean }) => tag.hidden === false),
    [tagsForSpecificSite]
  );

  const theme = useTheme();
  const showTagItem = !isParticularChart || !isTimelineChart;
  const showEventItem = !isParticularChart || isTimelineChart;

  useEffect(() => {
    if (isParticularChart) return;
    setValue(`events-${site.id}-${site.siteName}`, 'false');
  }, [site.id, setValue, isParticularChart, site.siteName]);

  const renderModalContentRows = (data: TagResponse[]) =>
    data?.map((tag) => (
      <ModalContentRow
        values={values}
        key={tag.id}
        tag={tag}
        siteName={site.siteName}
        setValue={setValue}
        siteId={site?.id}
        isParticularChart
      />
    ));

  return (
    <Accordion
      defaultExpanded={false}
      sx={{ backgroundColor: theme.palette.background.default }}
      onClick={getData}
    >
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        sx={{ width: '100%' }}
        data-sm="advanced-button-input"
      >
        <Typography>{site?.siteName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Stack flexDirection={'row'} justifyContent={'center'}>
            <CustomWaterLoading />
          </Stack>
        ) : (
          <Box>
            {showTagItem && newTags?.length > 0 ? renderModalContentRows(newTags) : null}
            {showEventItem && newTags?.length > 0 ? (
              <StyledModalContentRow
                sx={{ justifyContent: 'flex-start' }}
                onClick={() =>
                  setValue(
                    `events-${site.id}-${site.siteName}`,
                    (!(values[`events-${site.id}-${site.siteName}`] === 'true')).toString()
                  )
                }
              >
                <Checkbox
                  inputProps={{
                    // @ts-ignore
                    'data-sm': 'events-checkbox',
                  }}
                  checked={values[`events-${site.id}-${site.siteName}`] === 'true'}
                  name={`events-${site.id}-${site.siteName}`}
                  onChange={(event) => {
                    setValue(`events-${site.id}-${site.siteName}`, event.target.checked.toString());
                  }}
                />
                <Typography sx={{ fontWeight: 'bold' }}>Events</Typography>
              </StyledModalContentRow>
            ) : null}
          </Box>
        )}
        {!loading && newTags?.length < 1 ? <Typography>No available tags</Typography> : null}
      </AccordionDetails>
    </Accordion>
  );
};
