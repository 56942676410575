import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DraggableList } from '../draggable-tag-list';
import { PlusFillIcon } from 'src/assets';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { SitesService } from 'src/services/sites';
import { useSnackbar } from 'notistack';
import { useAuthentication } from 'src/features/authentication/context';
import { useResponsive } from 'src/hooks';
import { EditSitePreviewRowType } from '../../types';
import { reorder } from 'src/utilities';

type EditSitePreviewModalType = {
  initialRows: { value: EditSitePreviewRowType[]; loaded: boolean };
  dialogOpen: boolean;
  handleSetDialogOpen: (isOpen: boolean) => void;
  selectedSite: string | null;
  handleSetSelectedSite: (value: string | null) => void;
  onReorder?: (newRows: EditSitePreviewRowType[]) => void;
  onRemoveRow?: (newRows: EditSitePreviewRowType[]) => void;
  onTestSetRow?: (rowId: string, tagId: string, gatewayId: string, parentType: string) => void;
  getTags: () => void;
};

export const EditSitePreviewModal = ({
  initialRows,
  dialogOpen,
  handleSetDialogOpen,
  selectedSite,
  handleSetSelectedSite,
  onReorder,
  onRemoveRow,
  onTestSetRow,
  getTags,
}: EditSitePreviewModalType) => {
  const theme = useTheme();
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const isMobile = useResponsive('down', 'sm');
  const [isEditSitePreviewSubmitting, setIsEditSitePreviewSubmitting] = useState<boolean>(false);
  const [isTagFetchOver, setIsTagFetchOver] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const [dropdownData, setDropdownData] = useState<{ value: any[]; loaded: boolean }>({
    value: [],
    loaded: false,
  });
  const [mobileListWidth, setMobileListWidth] = useState<string>('100%');

  const [rows, setRows] = useState<EditSitePreviewRowType[]>(
    Array.from({ length: 1 }, (v, k) => k).map((k) => ({
      id: `${k + 1}`,
      position: k + 1,
      tagId: '',
      gatewayId: '',
      parentType: 'gateway',
    }))
  );

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (!selectedSite || !customerId) return;

    setRows(initialRows.value);
    setIsTagFetchOver(false);

    SitesService.getTags(customerId, selectedSite).then((response: any[]) => {
      setDropdownData({
        value: response,
        loaded: true,
      });
      setIsTagFetchOver(true);
    });
  }, [selectedSite, customerId, initialRows]);

  const rowsHaveValue = (): boolean => {
    let nonEmptyCount = 0;
    for (const item of rows) {
      if (item && item.tagId !== '') {
        nonEmptyCount++;
        if (nonEmptyCount >= 1) {
          return true;
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (rows.length <= 0) return;

    setSelectedTags(rows.map((row) => row.tagId));
    !rowsHaveValue() ? setMobileListWidth('100%') : setMobileListWidth('fit-content');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(rows, source.index, destination.index);

    setRows(newItems);

    if (onReorder) onReorder(newItems);
  };

  const addRow = () => {
    if (rows.length === 12) return;

    setRows((prevValue) => {
      const newRows: any[] = [];
      prevValue.forEach((val) => {
        newRows.push(val);
      });

      const maxId = newRows.reduce((max, obj) => {
        const id = Number(obj.id);
        return id > max ? id : max;
      }, 0);

      newRows.push({
        id: `${maxId + 1}`,
        position: maxId + 1,
        gatewayId: '',
        tagId: '',
        parentType: 'gateway',
      });

      return newRows.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
    });
  };

  const removeRow = (id: string) => {
    setRows((prevValue) => {
      const newRows: any[] = [];
      prevValue.forEach((val) => {
        if (val.id !== id) newRows.push(val);
      });

      return newRows.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
    });

    if (onRemoveRow) onRemoveRow(rows.filter((row) => row.id !== id));
  };

  const handleDialogClose = () => {
    handleSetDialogOpen(false);
    handleSetSelectedSite(null);
    setMobileListWidth('100%');
    setTimeout(() => {
      setRows([
        {
          id: `1`,
          position: 1,
          tagId: '',
          gatewayId: '',
          parentType: 'gateway',
        },
      ]);
    }, 200);
  };

  const handleSetRow = (rowId: string, tagId: string, gatewayId: string, parentType: string) => {
    setRows((prevValue) => {
      const newValues: any[] = [];
      prevValue.forEach((val) => {
        if (val.id === rowId) {
          newValues.push({
            id: val.id,
            position: val.position,
            gatewayId: gatewayId,
            tagId: tagId,
            parentType: (parentType as 'gateway') || 'dataSource',
          });
        } else {
          newValues.push(val);
        }
      });

      return newValues;
    });

    if (onTestSetRow) onTestSetRow(rowId, tagId, gatewayId, parentType);
  };

  const handleMobileListWidth = (value: string) => {
    setMobileListWidth(value);
  };

  const onEditSitePreviewSubmit = async () => {
    if (!customerId || !selectedSite) return;

    setIsEditSitePreviewSubmitting(true);

    const filteredRows = rows.filter((row) => row.tagId !== '');

    const tags = filteredRows.map((row) => ({
      tagId: row.tagId,
      gatewayId: row.gatewayId,
      position: row.position,
      parentType: row.parentType as 'gateway' | 'dataSource',
    }));
    const dataToSubmit = {
      customerId: customerId,
      siteId: selectedSite,
      tags: tags,
    };

    try {
      await SitesService.editSitePreview(dataToSubmit);
      setIsEditSitePreviewSubmitting(false);
      enqueueSnackbar('Site Preview has been updated successfully!', {
        variant: 'success',
      });
      await getTags();
    } catch (error) {
      setIsEditSitePreviewSubmitting(false);
      enqueueSnackbar('Site Preview update encountered an issue', {
        variant: 'error',
      });
    }

    handleDialogClose();
  };

  return (
    <Dialog open={dialogOpen} fullWidth onClose={handleDialogClose}>
      <DialogTitle>Edit Site Preview</DialogTitle>
      <Divider />
      <DialogContent sx={{ pt: '24px', pb: '12px' }}>
        <Typography variant="body2">
          Select tags for the site preview. Drag and drop to define the order.
        </Typography>
        <Stack
          sx={{
            overflowX: isMobile ? 'scroll' : 'unset',
            width: isMobile ? mobileListWidth : '100%',
            whiteSpace: 'nowrap',
          }}
        >
          {isTagFetchOver && initialRows.loaded ? (
            <DraggableList
              items={rows}
              selectedTags={selectedTags}
              onDragEnd={onDragEnd}
              onRemoveRow={removeRow}
              dropdownData={dropdownData}
              handleSetRow={handleSetRow}
              handleMobileListWidth={handleMobileListWidth}
            />
          ) : (
            <>
              {Array.from({ length: 4 }, (v, k) => k).map((k) => (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={'40px'}
                  width={'100%'}
                  sx={{ bgcolor: theme.palette.grey[300], mt: '12px' }}
                  key={k}
                />
              ))}
            </>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack sx={{ width: '100%' }} flexDirection={'row'} justifyContent={'space-between'}>
          <Button
            size={'medium'}
            variant="text"
            startIcon={<PlusFillIcon />}
            data-sm="add-new-tag"
            data-testid="add-new-tag"
            onClick={addRow}
            disabled={rows.length === 12}
          >
            {'Add New Tag'}
          </Button>
          <Stack flexDirection={'row'}>
            <Button
              onClick={handleDialogClose}
              size={'medium'}
              variant="outlined"
              data-sm="cancel-modal-update"
            >
              {'Cancel'}
            </Button>
            <LoadingButton
              onClick={onEditSitePreviewSubmit}
              loading={isEditSitePreviewSubmitting}
              size={'medium'}
              variant="contained"
              data-sm="save-modal-data"
              sx={{ ml: '8px' }}
            >
              {'Save'}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
