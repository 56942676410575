import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledLabelBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.grey[800],
  background: theme.palette.grey[300],
  borderRadius: '6px',
  fontWeight: 'bold',
  padding: '6px 8px',
}));

export { StyledLabelBox };
