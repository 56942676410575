import { useRoutes } from 'react-router-dom';
import {
  ROOT_PATH,
  ADD_CUSTOMER_ACCOUNT_PATH,
  SITES_MANAGEMENT_PATH,
  EDIT_CUSTOMER_ACCOUNT_PATH,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_LINK_SENT,
  LOGIN_PATH,
  USER_ACCOUNTS_PATH_ROOT,
  USER_ACCOUNTS_PATH_INVITE,
  ADD_SITE_PATH,
  USER_ACCOUNTS_PATH_EDIT,
  SITES_DASHBOARD_PATH,
  SITE_INFO_PATH,
  GATEWAY_PATH_ADD,
  GATEWAY_PATH_ROOT,
  ASSET_PATH_ROOT,
  ASSET_PATH_ADD,
  PUMP_REPORT_PATH,
  ALARM_RULES_PATH_ROOT,
  ALARM_RULES_PATH_ADD,
  ALARMS_PATH,
  TENANT_SWITCH_PATH,
  ACCOUNT_ISSUE_PATH,
  TRENDS_PATH,
  INTEGRATIONS_PATH,
  SET_PROFILE_PATH,
  ASSET_PATH_EDIT,
  USER_PROFILE_PATH,
  // CONTROLLERS_PATH_ROOT,
  // CONTROLLERS_PATH_ADD,
  // CONTROLLERS_PATH_EDIT,
  PAGE_NOT_FOUND,
  CONTROLLERS_PATH_ROOT,
  CONTROLLERS_PATH_ADD,
  CONTROLLERS_PATH_EDIT,
  ALARMS_NOTIFICATIONS_ADD_PATH,
  ALARMS_NOTIFICATIONS_PATH,
  ALARMS_NOTIFICATIONS_EDIT_PATH,
  SITES_MAP_PATH,
  ONE_CLICK_ALARM_ACKNOWLEDGEMENT_PATH,
  BWT_DEMO_PATH,
  REPORTS_NEW_PATH,
} from './config';
import { useAuthentication } from 'src/features/authentication/context';
import {
  AccountIssueElement,
  AlarmRulesAddElement,
  AlarmRulesEditElement,
  AlarmRulesManagementElement,
  AlarmsElement,
  AlarmsNotificationAddElement,
  AlarmsNotificationEditElement,
  AlarmsNotificationElement,
  AlarmsOneClickAcknowledgementElement,
  AssetAddElement,
  AssetEditElement,
  AssetManagementElement,
  ControllerAddElement,
  ControllerEditElement,
  ControllerManagementElement,
  CustomerAccountManagementElement,
  CustomerAddAccountElement,
  CustomerEditAccountElement,
  DataSourceEditElement,
  DataSourceManagementElement,
  ForgotPasswordElement,
  ForgotPasswordLinkSentElement,
  GatewayAddElement,
  GatewayEditElement,
  GatewayManagementElement,
  LoginElement,
  PumpReportElement,
  ReportNewElement,
  SetProfileElement,
  SiteAddElement,
  SiteDashboardElement,
  SiteInfoElement,
  SiteManagementElement,
  SiteMapElement,
  TenantSwitchElement,
  TrendsElement,
  TrendsElementNew,
  UserAccountEditElement,
  UserAccountInviteElement,
  UserAccountManagementElement,
  UserProfileElement,
} from './elements';
import { ACCESS_ROLES } from 'src/config';
import { PageNotFoundElement } from './elements/PageNotFoundFlow/PageNotFoundElement';
import featureFlags from '../feature-flags.json';

export default function Router() {
  const { getCurrentRole, user } = useAuthentication();

  const isSuperAdmin = getCurrentRole(null, null) === ACCESS_ROLES.SUPER_ADMIN;

  return useRoutes([
    {
      path: ROOT_PATH,
      element: <CustomerAccountManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ADD_CUSTOMER_ACCOUNT_PATH,
      element: <CustomerAddAccountElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: EDIT_CUSTOMER_ACCOUNT_PATH,
      element: <CustomerEditAccountElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: LOGIN_PATH,
      element: <LoginElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: BWT_DEMO_PATH,
      element: <LoginElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: FORGOT_PASSWORD,
      element: <ForgotPasswordElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: FORGOT_PASSWORD_LINK_SENT,
      element: <ForgotPasswordLinkSentElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: TENANT_SWITCH_PATH,
      element: <TenantSwitchElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ACCOUNT_ISSUE_PATH,
      element: <AccountIssueElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: SET_PROFILE_PATH,
      element: <SetProfileElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: USER_ACCOUNTS_PATH_ROOT,
      element: <UserAccountManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: USER_ACCOUNTS_PATH_ROOT + USER_ACCOUNTS_PATH_INVITE,
      element: <UserAccountInviteElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: USER_ACCOUNTS_PATH_ROOT + USER_ACCOUNTS_PATH_EDIT + '/:userId',
      element: <UserAccountEditElement isSuperAdmin={isSuperAdmin} />,
    },
    ...(featureFlags.userProfile === 1
      ? [
          {
            path: USER_PROFILE_PATH,
            element: <UserProfileElement isSuperAdmin={isSuperAdmin} />,
          },
        ]
      : []),

    {
      path: SITES_MANAGEMENT_PATH,
      element: <SiteManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: SITES_MAP_PATH,
      element: <SiteMapElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ADD_SITE_PATH,
      element: <SiteAddElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: SITES_DASHBOARD_PATH,
      element: <SiteDashboardElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: SITE_INFO_PATH,
      element: <SiteInfoElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: INTEGRATIONS_PATH,
      element: <DataSourceManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: INTEGRATIONS_PATH + '/:dataSourceId',
      element: <DataSourceEditElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: GATEWAY_PATH_ROOT,
      element: <GatewayManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: GATEWAY_PATH_ROOT + GATEWAY_PATH_ADD,
      element: <GatewayAddElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: GATEWAY_PATH_ROOT + '/:gatewayId',
      element: <GatewayEditElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ASSET_PATH_ROOT,
      element: <AssetManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ASSET_PATH_ROOT + ASSET_PATH_ADD,
      element: <AssetAddElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ASSET_PATH_ROOT + ASSET_PATH_EDIT + '/:assetId',
      element: <AssetEditElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ALARM_RULES_PATH_ROOT,
      element: <AlarmRulesManagementElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ALARM_RULES_PATH_ROOT + ALARM_RULES_PATH_ADD,
      element: <AlarmRulesAddElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ALARM_RULES_PATH_ROOT + '/:ruleId',
      element: <AlarmRulesEditElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ALARMS_PATH,
      element: <AlarmsElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: PUMP_REPORT_PATH,
      element: <PumpReportElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: TRENDS_PATH,
      element: <TrendsElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: `${TRENDS_PATH}-new`,
      element: <TrendsElementNew isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: PAGE_NOT_FOUND,
      element: <PageNotFoundElement isSuperAdmin={isSuperAdmin} />,
    },
    {
      path: ONE_CLICK_ALARM_ACKNOWLEDGEMENT_PATH,
      element: <AlarmsOneClickAcknowledgementElement />,
    },
    ...(featureFlags.alarmNotifications === 1 ||
    featureFlags.userIds.includes(user?.userId) ||
    isSuperAdmin
      ? [
          {
            path: 'alarms-notifications',
            element: <AlarmsNotificationElement isSuperAdmin={isSuperAdmin} />,
          },
          {
            path: ALARMS_NOTIFICATIONS_PATH + ALARMS_NOTIFICATIONS_ADD_PATH,
            element: <AlarmsNotificationAddElement isSuperAdmin={isSuperAdmin} />,
          },
          {
            path:
              ALARMS_NOTIFICATIONS_PATH +
              ALARMS_NOTIFICATIONS_EDIT_PATH +
              '/:alarmNotificationGroupId',
            element: <AlarmsNotificationEditElement isSuperAdmin={isSuperAdmin} />,
          },
        ]
      : []),
    ...(featureFlags.controllers === 1
      ? [
          {
            path: CONTROLLERS_PATH_ROOT,
            element: <ControllerManagementElement isSuperAdmin={isSuperAdmin} />,
          },
          {
            path: CONTROLLERS_PATH_ROOT + CONTROLLERS_PATH_ADD,
            element: <ControllerAddElement isSuperAdmin={isSuperAdmin} />,
          },
          {
            path: CONTROLLERS_PATH_ROOT + CONTROLLERS_PATH_EDIT + '/:controllerId',
            element: <ControllerEditElement isSuperAdmin={isSuperAdmin} />,
          },
        ]
      : []),
    ...(featureFlags.reportsNew === 1 || isSuperAdmin
      ? [
          {
            path: REPORTS_NEW_PATH,
            element: <ReportNewElement isSuperAdmin={isSuperAdmin} />,
          },
        ]
      : []),
  ]);
}
