import { LightTooltip } from 'src/components/minimals/tooltip/LightTooltip';
import { AlarmKind } from '../../types';
import { ErrorIcon, SuccessIcon, WarningIcon } from 'src/features/theme/overrides/CustomIcons';
import { useTheme } from '@mui/material/styles';

export const HandleAlarmView = (alarm: any) => {
  const theme = useTheme();
  switch (alarm) {
    case AlarmKind.LOW:
      return (
        <LightTooltip title="Warning" enterTouchDelay={0} placement="left-end" arrow>
          <div>
            <WarningIcon htmlColor={theme.palette.warning.main} fontSize="medium" />
          </div>
        </LightTooltip>
      );
    case AlarmKind.HIGH:
      return (
        <LightTooltip title="High" enterTouchDelay={0} placement="left-end" arrow>
          <div>
            <ErrorIcon htmlColor={theme.palette.error.main} fontSize="medium" />
          </div>
        </LightTooltip>
      );
  }
  return (
    <LightTooltip title="No active alarms" enterTouchDelay={0} placement="left-end" arrow>
      <div>
        <SuccessIcon htmlColor={theme.palette.success.main} fontSize="medium" />
      </div>
    </LightTooltip>
  );
};
