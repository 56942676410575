import { Box, Typography } from '@mui/material';
import { WidgetWrapper } from '../WidgetWrapper';
import { StateLabelBoxOn } from '../StateLabelBoxOn';
import { StateLabelBoxOff } from '../StateLabelBoxOff';
import { ValveWidget } from 'src/assets';
import { valveWrapper, svgBox, modeBox, modeTitle, modeValue } from './style';

type Props = {
  title: string;
  isOpen: boolean;
  mode: string;
};

export const Valve = ({ title, isOpen, mode }: Props) => (
  <WidgetWrapper title={title} isDataReady={true}>
    <Box sx={valveWrapper}>
      <Box sx={svgBox}>
        <ValveWidget />
      </Box>
      {isOpen ? <StateLabelBoxOn text="Open" /> : <StateLabelBoxOff text="Closed" />}
      <Box sx={modeBox}>
        <Typography sx={modeTitle}>Mode:</Typography>
        <Typography sx={modeValue}>{mode}</Typography>
      </Box>
    </Box>
  </WidgetWrapper>
);
