import { api, READINGS, PUMP_REPORT, COUNT_URL } from '../api';
import { GetAllByRangeParams } from './types';

const getAll = async (customerId: string, startDate: string, endDdate: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `v2/reports?customerId=${customerId}&startDate=${startDate}&endDate=${endDdate}&reportType=pump_report`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getCount = async (customerId: string, date: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${READINGS}/${customerId}${PUMP_REPORT}/${date}${COUNT_URL}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getAllByRange = async (params: GetAllByRangeParams) => {
  try {
    const response = await api({
      method: 'get',
      url: `${READINGS}/${params.customerId}${PUMP_REPORT}/between?fromDate=${params.fromDate}&toDate=${params.toDate}&limit=${params.limit}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const ReportsService = {
  getAll,
  getCount,
  getAllByRange,
};
