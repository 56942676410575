type PumpModalData = {
  date: string;
  site: string;
  asset: string;
  runtime: string;
  cycles: string;
  flow: string;
  efficiency: string;
  energyConsumption: string;
};

export enum AlarmType {
  INFO = 'info',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

type DataType = {
  averageDailyEfficiency?: number;
  dailyCycles?: number;
  dailyEnergyConsumption?: number;
  dailyRuntime?: number;
  dailyVolumetricFlow?: number;
};

type PumpData = {
  siteName: string;
  assetName: string;
  data: DataType;
};

export enum ReportType {
  PDF = 'PDF',
  ASSET = 'ASSET',
  DATA = 'DATA',
}

type Report = {
  id: string;
  type: ReportType;
  name: string;
  createdBy: string;
  modified: string;
  schedule: string;
  favorite: boolean;
};

type RangePumpData = PumpData & {
  date: string;
};

type AssetType = {
  assetName: string;
  data: DataType;
};

type Assets = {
  [key: string]: AssetType;
};

type SiteType = {
  siteName: string;
  assets: Assets;
};

export type { PumpModalData, PumpData, RangePumpData, SiteType, AssetType, Report };
