import {
  ASSETS_ALARM_TAGS,
  ASSETS_MANAGEMENT_URL,
  ASSETS_PROPERTIES,
  ASSETS_PROPERTY,
  ASSETS_TAGS,
  COUNT_URL,
  SITES_ROOT,
  VALUES,
  api,
} from '../api';
import {
  AddAssetTypes,
  GetAllAssetAlarmTagsByTypeTypes,
  GetAllAssetPropertiesByTypeTypes,
  GetAllAssetTagsByTypeTypes,
  GetAllParamsTypes,
  GetAssetByIdTypes,
  GetPredefinedValuesForAssetPropertyTypes,
  UpdateAssetTypes,
} from './types';

// SUMMARY:
// Endpoint for getting all gateways for specific site.
const getAll = async (customerId: string, siteId: string, params?: GetAllParamsTypes) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${ASSETS_MANAGEMENT_URL}`,
    params,
  });

  return response.data;
};

const getCount = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}${SITES_ROOT}/${siteId}${ASSETS_MANAGEMENT_URL}${COUNT_URL}`,
  });

  return response.data;
};

// SUMMARY:
// Endpoint for getting asset properties by asset type.
const getAssetPropertiesByAssetType = async (params: GetAllAssetPropertiesByTypeTypes) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${params.customerId}${SITES_ROOT}/${params.siteId}${ASSETS_MANAGEMENT_URL}${ASSETS_PROPERTIES}/${params.assetType}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for getting predefined values for a specific asset property.
// /sites/{customerId}/sites/{siteId}/assets/properties/{assetType}/property/{propertyName}/values:
const getPredefinedValuesForAssetProperty = async (
  params: GetPredefinedValuesForAssetPropertyTypes
) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${params.customerId}${SITES_ROOT}/${params.siteId}${ASSETS_MANAGEMENT_URL}${ASSETS_PROPERTIES}/${params.assetType}${ASSETS_PROPERTY}/${params.propertyName}${VALUES}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for adding asset.
const addAsset = async (params: AddAssetTypes) => {
  try {
    const response = await api({
      method: 'post',
      url: `${SITES_ROOT}/${params.customerId}${SITES_ROOT}/${params.siteId}${ASSETS_MANAGEMENT_URL}`,
      data: params.assetBody,
    });
    return response.status;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for getting asset tags by asset type.
const getAssetTagsByAssetType = async (params: GetAllAssetTagsByTypeTypes) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${params.customerId}${SITES_ROOT}/${params.siteId}${ASSETS_MANAGEMENT_URL}${ASSETS_TAGS}/${params.assetType}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for getting asset alarm tags by asset type.
const getAlarmTagsByAssetType = async (params: GetAllAssetAlarmTagsByTypeTypes) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${params.customerId}${SITES_ROOT}/${params.siteId}${ASSETS_MANAGEMENT_URL}${ASSETS_ALARM_TAGS}/${params.assetType}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for getting asset tags by asset type.
const getAssetById = async (params: GetAssetByIdTypes) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${params.customerId}/sites/${params.siteId}${ASSETS_MANAGEMENT_URL}/${params.assetId}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// SUMMARY:
// Endpoint for updating an asset.
const updateAsset = async (params: UpdateAssetTypes) => {
  try {
    const response = await api({
      method: 'patch',
      url: `${SITES_ROOT}/${params.customerId}/sites/${params.siteId}${ASSETS_MANAGEMENT_URL}/${params.assetId}`,
      data: params.assetBody,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const AssetService = {
  getAll,
  getCount,
  getAssetPropertiesByAssetType,
  getPredefinedValuesForAssetProperty,
  addAsset,
  updateAsset,
  getAssetTagsByAssetType,
  getAlarmTagsByAssetType,
  getAssetById,
};
