import React, { useState, useContext } from 'react';
import { ThemeTypeContextTypes, ThemeTypeProviderPropsTypes } from './type';

const ThemeTypeContext = React.createContext<ThemeTypeContextTypes>({} as ThemeTypeContextTypes);

export const useThemeType = () => useContext(ThemeTypeContext);

export const ThemeTypeProvider = ({ children }: ThemeTypeProviderPropsTypes) => {
  const [themeType, setThemeType] = useState<'light' | 'dark'>('light');

  const toggleThemeType = () => {
    setThemeType((prevValue) => (prevValue === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeTypeContext.Provider value={{ themeType, toggleThemeType }}>
      {children}
    </ThemeTypeContext.Provider>
  );
};
