import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ListIcon, UserIcon } from 'src/assets';
import { IconWrapper, Overlay, StyledHeader, iconBox } from './style';
import { useAuthentication } from 'src/features/authentication/context';
import { useHtmlElement, useResponsive, useToggle } from 'src/hooks';
import { MenuPopover, MenuPopoverItem } from 'src/components';
import { Link } from 'react-router-dom';
import { TENANT_SWITCH_PATH } from 'src/routes/config';
import { useThemeType } from 'src/contexts/themeTypeContext/theme-type-context';
import featureFlags from '../../feature-flags.json';
import { ACCESS_ROLES } from 'src/config';
import { useMapFilters } from 'src/contexts/siteMapFilterContext';
type HeaderProps = {
  title?: string;
  showButton?: boolean;
  isTransparent?: boolean;
};

export const Header = ({ title, showButton = false, isTransparent = false }: HeaderProps) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[700];
  const greyLightColor = theme.palette.grey[300];
  const whiteColor = theme.palette.common.white;
  const textDisabledColor = theme.palette.text.disabled;

  const { state, onToggle } = useToggle();
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const { user, logout } = useAuthentication();
  const isMobile = useResponsive('down', 'sm');
  const { themeType, toggleThemeType } = useThemeType();

  const { customerId, getCurrentRole } = useAuthentication();
  const isSuperAdmin = getCurrentRole(null, null) === ACCESS_ROLES.SUPER_ADMIN;
  const { updateMapFilters } = useMapFilters();

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    addHtmlElement(event.currentTarget);
    onToggle();
  };

  const handleMenuClose = () => {
    removeHtmlElement();
    onToggle();
  };

  const handleProfileClick = () => {};

  const handleLogoutClick = () => {
    logout();
  };

  const handleThemeSwitch = () => {
    toggleThemeType();
  };

  const handleAccountSwitch = () => {
    updateMapFilters('');
  };

  return (
    <StyledHeader
      isTransparent={isTransparent}
      component="header"
      data-sm="layout:header"
      theme={theme}
      isMobile={isMobile}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={title ? 'space-between' : 'flex-end'}
        alignItems={'center'}
        sx={{ width: '100%' }}
      >
        {title ? (
          <Box component="div">
            <Typography variant="h4" component="h1" data-sm="title">
              {title}
            </Typography>
          </Box>
        ) : null}
        <Stack flexDirection={'row'}>
          {showButton &&
            (isMobile ? (
              <IconButton to={TENANT_SWITCH_PATH} component={Link} onClick={handleAccountSwitch}>
                <ListIcon width={24} height={24} />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                startIcon={<ListIcon width={18} height={18} />}
                sx={{ mr: '40px' }}
                to={TENANT_SWITCH_PATH}
                component={Link}
                onClick={handleAccountSwitch}
              >
                Switch Account
              </Button>
            ))}
          <Box sx={iconBox}>
            <IconWrapper onClick={(event) => handleMenuOpen(event)} data-sm={'profile-icon'}>
              <Overlay active={state.toString()} />
              <UserIcon fill={greyColor} outline={isTransparent ? whiteColor : greyLightColor} />
            </IconWrapper>
            <MenuPopover open={htmlElement} onClose={handleMenuClose} arrow="top-right">
              <MenuPopoverItem
                sx={{ px: '20px!important', py: '8px!important' }}
                customContent={
                  <Stack>
                    <Typography variant="subtitle1">
                      {user?.firstName + ' ' + user?.lastName}
                    </Typography>
                    <Typography variant="body2" color={textDisabledColor}>
                      {user?.email}
                    </Typography>
                  </Stack>
                }
                handleClick={handleProfileClick}
                testId="profile-button"
              />
              {featureFlags.userProfile === 1 && !isSuperAdmin ? (
                <Link to={`/profile?customerId=${customerId.value}`}>
                  <MenuPopoverItem
                    sx={{ px: '15px!important', py: '6px!important' }}
                    customContent={
                      <Button
                        sx={{
                          width: '100%',
                          color: theme.palette.common.black,
                          fontSize: '16px',
                          fontWeight: theme.typography.fontWeightRegular,
                          padding: '0',
                          '&:hover': {
                            background: 'none',
                          },
                        }}
                        variant="text"
                        data-sm="add-site-button"
                      >
                        Profile
                      </Button>
                    }
                    handleClick={() => {}}
                    testId="profile-button-cta"
                  />
                </Link>
              ) : null}
              <Divider />
              {featureFlags.themeMode ? (
                <MenuPopoverItem
                  sx={{ px: '20px!important', py: '8px!important' }}
                  customContent={
                    <Typography>
                      Switch to {themeType === 'dark' ? 'light mode' : 'dark mode'}
                    </Typography>
                  }
                  handleClick={handleThemeSwitch}
                  testId="toggle-theme-button"
                />
              ) : null}
              <MenuPopoverItem
                sx={{ px: '20px!important', py: '8px!important' }}
                customContent={<Typography>Logout</Typography>}
                handleClick={handleLogoutClick}
                testId="logout-button"
              />
            </MenuPopover>
          </Box>
        </Stack>
      </Stack>
    </StyledHeader>
  );
};
