export const card = (
  width: number,
  height: number,
  isDesktop: boolean,
  isTrendWidget: boolean,
  isPumpWidget: boolean,
  isControllerWidget: boolean
) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: isDesktop ? '370px' : '100%',
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  padding: isPumpWidget || isControllerWidget ? '20px' : '30px 20px 55px 20px',
  borderRadius: '16px',
  background: '#ffffff',
  width: width ? `${width}px` : '100%',
  height: height ? `${height}px` : '100%',
  minHeight: 'auto',
  position: 'relative',
  margin: '0 auto',
  maxHeight:
    !isDesktop && isTrendWidget
      ? '280px'
      : (isControllerWidget || isPumpWidget) && isDesktop
      ? '166px'
      : 'auto',
});

export const cardHeader = {
  display: 'flex',
  alignItems: 'center',
  maxHeight: '56px',
  marginBottom: '0.2rem',
};

export const cardTitle = {
  flex: '1 1 auto',
  lineBreak: 'anywhere',
  maxHeight: '56px',
  overflow: 'hidden',
};

export const cardContext = {
  flex: '0 0 30px',
};

export const wrapperIconsBox = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '1.2rem',
};

export const cardGraphic = {
  position: 'relative',
  border: '1px solid rgba(86, 99, 105, 0.4)',
  padding: '30px 10px 15px',
  height: '390px',
  margin: '0 90px 0 50px',
};

export const cardGraphicInner = {
  position: 'relative',
  height: '100%',
  backgroundColor: '#F4F4F7',
  display: 'flex',
  justifyContent: 'center',
};

export const cardGraphicLevel = (waterLevelDisplay: number | null) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  backgroundColor: 'rgba(36, 134, 253, 0.2)',
  borderTop: `${waterLevelDisplay !== null ? '2px solid #2486FD' : 'none'}`,
  height: `${waterLevelDisplay}%`,
});

export const cardGraphicText = (waterLevelDisplay: number | null) => ({
  display: 'block',
  position: 'absolute',
  bottom: `${waterLevelDisplay !== null ? waterLevelDisplay : 0}%`,
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  zIndex: 8,
});

export const cardGraphicLabel = {
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
  maxWidth: '85px',
  position: 'absolute',
  zIndex: 1,
  transform: 'translateY(50%)',
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '15px',
};

export const cardGraphicLabelLeft = {
  textAlign: 'right',
  right: 'calc(100% + 20px)',
  '&::after': {
    content: '""',
    display: 'inline-block',
    width: '10px',
    height: '1px',
    backgroundColor: '#acacac',
    marginLeft: '10px',
  },
};

export const cardGraphicLabelRight = {
  left: 'calc(100% + 20px)',
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: '10px',
    height: '1px',
    backgroundColor: '#acacac',
    marginRight: '10px',
  },
};

export const cardPumpStatus = {
  background: '#fffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const cardPumpImage = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
};

export const cardPumpMode = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
};

export const cardTowerInner = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const cardTowerHead = {
  width: '60%',
  border: '1px solid rgba(86, 99, 105, 0.32)',
  height: '190px',
  padding: '7px',
  borderRadius: '45px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
};

export const cardTowerHeadInner = {
  width: '100%',
  height: '100%',
  borderRadius: '45px',
  background: '#F4F4F7',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const cardTowerWater = (waterLevelDisplay: number) => ({
  width: '100%',
  height: `${waterLevelDisplay}%`,
  background: 'rgba(36, 134, 253, 0.2)',
  borderRadius: '0 0 45px 45px',
  bottom: 0,
  position: 'absolute',
  borderTop: '2px solid #2486FD',
});

export const cardTowerGraphic = {
  position: 'relative',
  width: '64.9%',
  margin: '0 60px 0 35px',
};

export const cardTowerGraphicAnimation = {
  position: 'absolute',
  top: '2.5%',
  left: '7.2%',
  width: '85%',
  height: '37%',
  backgroundColor: '#F4F4F7',
  borderRadius: '28%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
};

export const cardTowerGraphicAnimationWrapper = {
  position: 'absolute',
  top: '2.5%',
  left: '7.2%',
  width: '85%',
  height: '37%',
  backgroundColor: '#F4F4F7',
  borderRadius: '28%',
  display: 'flex',
  justifyContent: 'center',
};

export const cardTowerGraphicLevel = (waterLevelDisplay: number | null) => ({
  display: 'block',
  backgroundColor: 'rgba(36, 134, 253, 0.2)',
  width: '100%',
  borderTop: waterLevelDisplay !== null ? '2px solid #2486FD' : 'none',
  position: 'absolute',
  bottom: 0,
  left: 0,
  transition: 'height 0.3s ease',
  height: `${waterLevelDisplay !== null ? waterLevelDisplay : 0}%`,
});

export const cardChartWrapper = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '20px',
  width: '100%',
};

export const cardChartLabel = {
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  position: 'absolute',
  transform: 'rotate(-90deg)',
  left: 0,
  bottom: '25px',
  color: '#919EAB',
};

export const spinnerBox = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const unitWetWellLabel = {
  fontSize: '0.8rem',
};
