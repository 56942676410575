import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MobileSiteCard } from '../mobile-site-card';
import { mobileSiteContainer, mobileSiteList, numOfItemsBox, StyledLoadMoreButton } from './style';
import { SiteManagementProps } from 'src/features/sites/types/sites';
import { SiteManagementRowsSkeleton } from '../../custom-skeletons';
import { ContentLoadingError } from 'src/components';
import { useEffect, useRef, useState } from 'react';
import { TagsTypes } from 'src/features/sites/types/types';
export const MobileSiteList = ({
  data,
  dataCount,
  pageNum,
  onChangePage,
  search,
  handleDialogOpen,
  tags,
  skipTo,
  roleAccessInfoBox,
  liveData,
}: SiteManagementProps) => {
  const theme = useTheme();
  const previousDataLength = useRef(0);
  const [filteredDataLength, setFilteredDataLength] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const mobileLimit = 100;

  useEffect(() => {
    if (search !== '') {
      setFilteredDataLength(data.value?.length ?? 0);
    } else {
      setFilteredDataLength(dataCount);
    }
  }, [search, dataCount, data.value]);

  useEffect(() => {
    const parsedSkipTo = skipTo ? parseInt(skipTo) : 0;
    if (parsedSkipTo * mobileLimit > filteredDataLength) {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [filteredDataLength, skipTo]);

  const onHandleClick = () => {
    previousDataLength.current = data.value?.length ?? 0;
    onChangePage(null, pageNum + 1);
  };
  const listElementRefs = useRef<(HTMLDivElement | null)[]>([]);
  useEffect(() => {
    const mobileLimit = 100;
    if (
      data.loaded &&
      data.value &&
      filteredDataLength !== undefined &&
      previousDataLength.current < filteredDataLength &&
      data.value.length > mobileLimit
    ) {
      const firstNewElement = listElementRefs.current[previousDataLength.current];

      if (firstNewElement) {
        const offset = 88; // header height on mobile
        const elementPosition = firstNewElement.getBoundingClientRect().top + window.scrollY;
        const scrollToPosition = elementPosition - offset;

        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [data, filteredDataLength]);

  return (
    <Box sx={mobileSiteContainer}>
      <Box sx={mobileSiteList}>
        {data.loaded ? (
          data.value?.map((site, index) => {
            const findTagsBySiteId = tags.find((item: TagsTypes) => item.siteId === site.id);
            return (
              <MobileSiteCard
                itemRefs={listElementRefs}
                key={site.id}
                site={site}
                index={index}
                tags={findTagsBySiteId}
                handleDialogOpen={handleDialogOpen}
                roleAccessInfoBox={roleAccessInfoBox}
                liveData={liveData}
              />
            );
          })
        ) : (
          <SiteManagementRowsSkeleton rowsNum={8} isMobile={true} />
        )}
      </Box>
      {!data.value ? (
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <ContentLoadingError isMobile={true} />
        </Stack>
      ) : null}
      {data.loaded ? (
        <Box sx={numOfItemsBox}>
          <Typography variant="body2">
            Displaying: {data.value && data.value.length > 0 ? 1 : 0}-{data.value?.length ?? 0} of{' '}
            {dataCount}
          </Typography>
        </Box>
      ) : (
        <Stack justifyContent="center" alignItems="center">
          <Skeleton animation={'wave'} variant="rounded" width={'75%'} height={'22px'} />
        </Stack>
      )}
      {(data.value?.length ?? 0) !== dataCount ? (
        <Stack justifyContent="center" alignItems="center">
          <StyledLoadMoreButton
            variant="contained"
            size="large"
            theme={theme}
            onClick={onHandleClick}
            disabled={isDisabled}
          >
            Load More
          </StyledLoadMoreButton>
        </Stack>
      ) : null}
    </Box>
  );
};
