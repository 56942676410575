import { Box } from '@mui/material';
import { GetAllResponse } from 'src/services/sites/types';
import { SelectedItemLabel } from '../selected-item-label';
import { selectedItemsList } from './style';

type SelectedItemsListTypes = {
  values: any;
  sites?: GetAllResponse[];
  setValue: (name: string, value: string) => void;
};

export const SelectedItemsList = ({ values, sites, setValue }: SelectedItemsListTypes) => {
  const valuesArr = Object.entries(values);

  const defaultSelectedElements = valuesArr.filter((item) => item[1] === 'true');

  return (
    <Box component="ul" sx={selectedItemsList}>
      {defaultSelectedElements.map((selectedValue) => (
        <SelectedItemLabel
          key={selectedValue[0]}
          selectedValue={selectedValue}
          sites={sites}
          setValue={setValue}
        />
      ))}
    </Box>
  );
};
