const TABLE_HEAD = [
  { id: 'customerName', label: 'Customer Name', align: 'left', testId: 'customer-name-header' },
  {
    id: 'businessAddress',
    label: 'Business Address',
    align: 'left',
    testId: 'business-address-header',
  },
  { id: 'status', label: 'Status', align: 'left', testId: 'status-header' },
  { id: '', testId: 'context-menu-header' },
];

const ADD_NEW_ACCOUNT_BUTTON_LABEL = 'Add Account';

const FILTER_BUTTON_LABEL = 'Filter';

const LOCK_ALERT_PRIMARY_TEXT = 'Are you sure you want to lock ';
const LOCK_ALERT_SECONDARY_TEXT =
  "Locking an account will prevent users from being able to access it. All account data will be preserved, Sites' data will continue to be recorded and you will still be able to access / unlock the account.";
const LOCK_ALERT_TITLE = 'Lock Customer Account';
const LOCK_ALERT_BUTTON_LABEL = 'Yes, Lock';

const UNLOCK_ALERT_PRIMARY_TEXT = 'Are you sure you want to unlock ';
const UNLOCK_ALERT_TITLE = 'Unlock Customer Account';
const UNLOCK_ALERT_BUTTON_LABEL = 'Yes, Unlock';

const POPOVER_EDIT_DESCRIPTION = 'Edit';
const POPOVER_LOCK_DESCRIPTION = 'Lock';
const POPOVER_UNLOCK_DESCRIPTION = 'Unlock';

export {
  TABLE_HEAD,
  ADD_NEW_ACCOUNT_BUTTON_LABEL,
  FILTER_BUTTON_LABEL,
  LOCK_ALERT_PRIMARY_TEXT,
  LOCK_ALERT_SECONDARY_TEXT,
  LOCK_ALERT_TITLE,
  LOCK_ALERT_BUTTON_LABEL,
  UNLOCK_ALERT_PRIMARY_TEXT,
  UNLOCK_ALERT_TITLE,
  UNLOCK_ALERT_BUTTON_LABEL,
  POPOVER_EDIT_DESCRIPTION,
  POPOVER_LOCK_DESCRIPTION,
  POPOVER_UNLOCK_DESCRIPTION,
};
