const TABLE_HEAD_REPORTS = [
  { id: 'siteName', label: 'Site Name', align: 'left', testId: 'report-site-name-header' },
  {
    id: 'asset',
    label: 'Asset',
    align: 'left',
    testId: 'report-asset-header',
  },
  {
    id: 'dailyRuntime',
    label: 'Daily Runtime[Hours]',
    align: 'left',
    testId: 'report-daily-runtime-header',
  },
  {
    id: 'dailyCycles',
    label: 'Daily Cycles',
    align: 'left',
    testId: 'report-daily-cycles-header',
  },
  {
    id: 'dailyVolumetricFlow',
    label: 'Daily Volumetric Flow',
    align: 'left',
    testId: 'report-daily-volumetric-flow-header',
  },
  {
    id: 'averageDailyEfficiency',
    label: 'Average Daily Efficiency',
    align: 'left',
    testId: 'report-average-daily-efficiency-header',
  },
  {
    id: 'totalDailyEnergyConsumption',
    label: 'Total Daily Energy Consumption',
    align: 'left',
    testId: 'report-total-daily-energy-consumption-header',
  },
];

const TABLE_HEAD_REPORTS_NEW = [
  { id: 'type', label: 'Type', align: 'left', testId: 'report-type-header' },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    testId: 'report-name-header',
  },
  {
    id: 'createdBy',
    label: 'Created By',
    align: 'left',
    testId: 'report-created-by-header',
  },
  {
    id: 'modified',
    label: 'Modified',
    align: 'left',
    testId: 'report-modified-header',
  },
  {
    id: 'schedule',
    label: 'Schedule',
    align: 'left',
    testId: 'report-schedule-header',
  },
  {
    id: 'favorite',
    label: 'Favorite',
    align: 'left',
    testId: 'report-favorite-header',
  },
  { id: '', testId: 'context-menu-header' },
];

export { TABLE_HEAD_REPORTS, TABLE_HEAD_REPORTS_NEW };
