import { styled, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const StyledLabelOnBox = styled(Box)(({ theme }: StylesTypes) => ({
  display: 'inline-block',
  background: theme.palette.primary.lighter,
  padding: '2px 8px',
  borderRadius: '6px',
}));

const StyledLabelText = styled(Typography)(({ theme }: StylesTypes) => ({
  color: theme.palette.primary.main,
  fontSize: '12px',
  fontWeight: '700',
}));

export { StyledLabelOnBox, StyledLabelText };
