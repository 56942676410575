import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from '@mui/material';

import { CustomBreadcrumbs } from 'src/components';
import { BREADCRUMBS_ROOT, BREADCRUMBS_EDITFORM, INTEGRATIONS_LABEL } from '../config';
import { SITES_MANAGEMENT_PATH, INTEGRATIONS_PATH } from 'src/routes/config';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { useSnackbar } from 'notistack';
import { DataSource } from '../types';
import { EditDataSourceForm } from '../components';
import { DataSourceService } from 'src/services/dataSources';
import { useAuthentication } from 'src/features/authentication/context';
import { useDocumentTitle } from 'src/hooks';

export const EditDataSource = () => {
  const [dataSource, setDataSource] = useState<DataSource>({
    dataSourceName: '',
    dataSourceType: 'FX30',
    id: '',
    dataSourceStatus: '',
    inputs: [],
  });
  const { dataSourceId } = useParams();
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const { getSite } = useSite();
  const { enqueueSnackbar } = useSnackbar();

  useDocumentTitle('Streametric - Edit Integration');

  useEffect(() => {
    if (!customerId || !siteId || !dataSourceId) return;
    DataSourceService.getById(customerId, siteId, dataSourceId)
      .then((data) => {
        setDataSource(data);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error.response.data.detail || error.message, {
            variant: 'error',
          });
        }
      });
  }, [customerId, enqueueSnackbar, dataSourceId, siteId]);

  return (
    <Container maxWidth={false} disableGutters>
      <CustomBreadcrumbs
        links={[
          {
            name: BREADCRUMBS_ROOT,
            href: SITES_MANAGEMENT_PATH + `?customerId=${customerId}`,
            testId: 'breadcrumb-root',
          },
          {
            name: getSite()?.siteName,
            href: `${SITES_MANAGEMENT_PATH}/dashboard?customerId=${customerId}&siteId=${siteId}`,
            testId: 'site-breadcrumb',
          },
          {
            name: INTEGRATIONS_LABEL,
            href: `${INTEGRATIONS_PATH}?customerId=${customerId}&siteId=${siteId}`,
            testId: 'data-sources-breadcrumb',
          },
          {
            name: `${BREADCRUMBS_EDITFORM}${dataSourceId}`,
          },
        ]}
        sx={{ my: '35px' }}
      />
      <EditDataSourceForm dataSource={dataSource} />
    </Container>
  );
};

export default EditDataSource;
