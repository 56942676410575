import { GATEWAY_PATH_ROOT, INPUTS_PATH_ROOT } from 'src/routes/config';
import { api, COUNT_URL, SITES_ROOT, SITE_PREVIEWS } from '../api';
import {
  AddNewSiteParams,
  EditParamsTypes,
  EditSitePreviewType,
  GetAllParamsTypes,
  GetByIdParamsType,
} from './types';
import { NewSite } from 'src/features/sites/types/sites';
import { concat } from 'lodash';

const prepareQueryParams = (params: GetAllParamsTypes) => {
  const skipToValue: string =
    params.skipTo && params.skipTo !== '' ? `&skipTo=${params.skipTo}` : '';
  const sortByValue: string =
    params.sortBy && params.sortBy !== '' ? `&sortBy=${params.sortBy}` : '';
  const descendingValue: string =
    params.descending !== undefined ? `&descending=${params.descending}` : '';
  const searchValue: string =
    params.search && params.search !== '' ? `&search=${params.search}` : '';
  // supports multiple siteType query params
  const siteTypeValue: string =
    params.siteType && params.siteType !== '' ? `&${params.siteType}` : '';

  return {
    skipToValue,
    sortByValue,
    descendingValue,
    searchValue,
    siteTypeValue,
  };
};

const getAll = async (params: GetAllParamsTypes) => {
  const preparedParams = prepareQueryParams(params);
  try {
    const response = await api({
      method: 'get',
      url: params.limit
        ? `${SITES_ROOT}/${params.customerId}?limit=${params.limit}${preparedParams.skipToValue}${preparedParams.sortByValue}${preparedParams.descendingValue}${preparedParams.searchValue}${preparedParams.siteTypeValue}`
        : `${SITES_ROOT}/${params.customerId}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getAllWithLoop = async (params: GetAllParamsTypes) => {
  let response: NewSite[] = [];
  params.skipTo = '1';
  try {
    let skipTo = 1;
    while (true) {
      params.skipTo = skipTo.toString();
      const data: NewSite[] = await getAll(params);

      if (data.length === 0) {
        break;
      }

      response = concat(response, data);
      skipTo++;
    }

    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const add = async (data: AddNewSiteParams, customerId: string) => {
  try {
    const response = await api({
      method: 'post',
      url: `${SITES_ROOT}/${customerId}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getById = async ({ customerId, siteId }: GetByIdParamsType) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${customerId}/${siteId}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getCount = async (customerId: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${SITES_ROOT}/${customerId}${COUNT_URL}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const edit = async (data: EditParamsTypes, customerId: string, siteId: string) => {
  const response = await api({
    method: 'patch',
    url: `${SITES_ROOT}/${customerId}/${siteId}`,
    data,
  });
  return response.data;
};

const getTags = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAY_PATH_ROOT}${INPUTS_PATH_ROOT}`,
  });

  return response.data;
};

const deleteInput = async (
  customerId: string,
  siteId: string,
  gatewayId: string,
  inputId: string
) => {
  const response = await api({
    method: 'delete',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAY_PATH_ROOT}/${gatewayId}${INPUTS_PATH_ROOT}/${inputId}`,
  });

  return response.data;
};

const getTagById = async (
  customerId: string,
  siteId: string,
  gatewayId: string,
  inputId: string
) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${customerId}/${siteId}${GATEWAY_PATH_ROOT}/${gatewayId}/input/${inputId}`,
  });

  return response.data;
};

const getEditSitePreview = async (customerId: string, siteId: string) => {
  const response = await api({
    method: 'get',
    url: `v2/site/preview?customerId=${customerId}&siteId=${siteId}`,
  });
  return response.data;
};

const editSitePreview = async (data: EditSitePreviewType) => {
  const response = await api({
    method: 'put',
    url: `v2/site/previews`,
    data,
  });
  return response.data;
};

const getTagsV2 = async (customerId: string, siteIds: string[] | undefined) => {
  const queryParams = [customerId && `customerId=${customerId}`].filter(Boolean).join('&');

  const response = await api({
    method: 'get',
    url: `v2/${SITE_PREVIEWS}?${queryParams}`,
  });

  return response;
};

export const SitesService = {
  getAll,
  getAllWithLoop,
  add,
  getById,
  edit,
  getTags,
  getCount,
  deleteInput,
  getTagById,
  editSitePreview,
  getEditSitePreview,
  getTagsV2,
};
