import React, { useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { createCommon, palette, scrollbar } from './palette/palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import { useLocation } from 'react-router';
import { Palette } from './palette/types';
import { useThemeType } from 'src/contexts/themeTypeContext/theme-type-context';
import { ThemeService } from 'src/services/themes';
import { Theme } from './type';
import { useCustomer } from 'src/contexts/customerContext/customer.context';

declare module '@mui/material/styles' {
  interface Theme {
    scrollbar: {
      thumb: string;
      thumbHover: string;
      background: string;
    };
    sidebar: {
      background: string;
      unselectedText: string;
      selectedSiteBackground: string;
      selectedNavItemBackground: string;
      alarmNotificationColor: string;
    };
  }
  interface ThemeOptions {
    scrollbar?: {
      thumb?: string;
      thumbHover?: string;
      background?: string;
    };
    sidebar: {
      background: string;
      unselectedText: string;
      selectedSiteBackground: string;
      selectedNavItemBackground: string;
      alarmNotificationColor: string;
    };
  }
}

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const defaultAppTheme: Palette = {
    lighter: '#CDF0F0',
    light: '#69D3D1',
    main: '#05B6B2',
    dark: '#398599',
    darker: '#005249',
    contrastText: '#fff',
  };
  const defaultSidebarTheme: ThemeOptions['sidebar'] = {
    background: '#21374E',
    unselectedText: '#BCC7D1',
    selectedSiteBackground: '#2B4764',
    selectedNavItemBackground: '#3A5776',
    alarmNotificationColor: '#FF4842',
  };

  const [customerTheme, setCustomerTheme] = useState<Theme | null>(null);
  const [appTheme, setAppTheme] = useState<Palette>(defaultAppTheme);
  const [sidebarTheme, setSidebarTheme] = useState<ThemeOptions['sidebar']>(defaultSidebarTheme);

  const location = useLocation();
  const { themeType } = useThemeType();
  const customerId = location.search.slice(12, 38);
  const { themeIds } = useCustomer(customerId);
  const fetchTheme = useCallback(async () => {
    try {
      if (customerId && themeIds && themeIds.activeThemeId !== undefined) {
        const themeData = await ThemeService.getById(customerId, themeIds.activeThemeId, false);
        setCustomerTheme({
          id: themeData.id,
          customerId: themeData.customerId,
          themeName: themeData.themeName,
          primary: themeData.primary,
          sidebar: themeData.sidebar,
        });
      } else {
        setCustomerTheme(null);
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    } finally {
    }
  }, [customerId, themeIds]);

  useLayoutEffect(() => {
    fetchTheme();
  }, [fetchTheme]);

  useLayoutEffect(() => {
    if (customerTheme) {
      if (
        customerTheme.primary &&
        !Object.values(customerTheme.primary).every((x) => x === null || x === '')
      ) {
        setAppTheme(customerTheme.primary);
      }
      if (
        customerTheme.sidebar &&
        !Object.values(customerTheme.sidebar).every((x) => x === null || x === '')
      ) {
        setSidebarTheme(customerTheme.sidebar);
      }
    } else {
      setAppTheme(defaultAppTheme);
      setSidebarTheme(defaultSidebarTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTheme]);

  const customSidebar = useCallback(
    (themeMode: 'light' | 'dark') => ({
      background: sidebarTheme.background,
      unselectedText: sidebarTheme.unselectedText,
      selectedSiteBackground: sidebarTheme.selectedSiteBackground,
      selectedNavItemBackground: sidebarTheme.selectedNavItemBackground,
      alarmNotificationColor: sidebarTheme.alarmNotificationColor,
    }),
    [sidebarTheme]
  );

  const CURRENT_COMMON = useMemo(() => createCommon(appTheme), [appTheme]);

  const themeColors = useMemo(
    () => palette(themeType, CURRENT_COMMON),
    [themeType, CURRENT_COMMON]
  );

  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      palette: themeColors,
      typography,
      shape: { borderRadius: 8 },
      direction: 'ltr',
      shadows: shadows(themeType),
      customShadows: customShadows(themeType, themeColors),
      scrollbar: scrollbar(themeType),
      sidebar: customSidebar(themeType),
    }),
    [themeColors, themeType, customSidebar]
  );

  const theme = useMemo(() => {
    const createdTheme = createTheme(themeOptions);
    createdTheme.components = componentsOverride(createdTheme);
    return createdTheme;
  }, [themeOptions]);
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
