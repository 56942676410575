import { useState, useEffect } from 'react';
import { AssetType } from '../types/types';
import { useWebSockets } from './useWebSockets';
import { PUMP_TAG_NAME } from '../config';
import { getIndexOfLastElement } from '../utils';

type usePumpDataArgumentsTypes = {
  asset: AssetType;
  customerId: string | null;
};

export const usePumpData = ({ asset, customerId }: usePumpDataArgumentsTypes) => {
  const [isPumpActive, setIsPumpActive] = useState<boolean | null>(false);

  const assetAssignedTag = asset?.assignedTags?.find((tag) => tag?.tagName === PUMP_TAG_NAME);

  const { messages } = useWebSockets(assetAssignedTag?.assignedGatewayId!);
  const indexOfLastElement = getIndexOfLastElement(messages);

  const dataSocket = messages[indexOfLastElement];
  const gatewayInputs = dataSocket?.payload.data.onCreatedReading.gateway_inputs;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === assetAssignedTag?.assignedTagId
  );

  useEffect(() => {
    // eslint-disable-next-line
    if (assetAssignedTag?.assignedGatewayId) setIsPumpActive(findInput?.value == 1 ? true : false);
  }, [assetAssignedTag?.assignedGatewayId, findInput?.value]);

  return { isPumpActive };
};
