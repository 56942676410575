import { useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { CloseIconV2, SearchFillIcon } from 'src/assets';
import { SearchBarProps } from 'src/features/sites/types/search';
import { mapSearchTextfield, mapSearchTextfieldBox } from '../../map-search/style';
export const SiteSearchBar = ({
  searchValue,
  setSearchValue,
  setSiteData,
  iconRight,
  placeholder,
  onBlur,
  onFocus,
}: SearchBarProps) => {
  const theme = useTheme();
  const [value, setValue] = useState('');

  const disabledTextColor = theme.palette.text.disabled;

  const handleSearchChange = (val: string) => {
    setSearchValue(encodeURIComponent(val));

    if (!searchValue) {
      setValue(val);
    }
  };

  const handleClearSearch = () => {
    setValue('');
    setSearchValue('');
    if (setSiteData) {
      setSiteData({ value: [], loaded: false });
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        ...(iconRight && mapSearchTextfieldBox),
      }}
    >
      <TextField
        data-testid="site-search"
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: '8px',
          ...(!searchValue ? mapSearchTextfield : {}),
        }}
        onChange={(e) => handleSearchChange(e.target.value)}
        value={searchValue ? decodeURIComponent(searchValue) : decodeURIComponent(value)}
        name="search"
        fullWidth
        placeholder={placeholder ?? 'Search for Site Name or Address'}
        inputProps={{ style: { padding: '10px' }, 'data-sm': 'search-input' }}
        InputProps={{
          sx: {
            height: '55px',
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchFillIcon fill={disabledTextColor} />
            </InputAdornment>
          ),
          endAdornment:
            searchValue || value ? (
              <IconButton onClick={handleClearSearch}>
                <CloseIconV2 fill={theme.palette.error.main} />
              </IconButton>
            ) : null,
          onBlur: onBlur,
          onFocus: onFocus,
        }}
      />
      {iconRight}
    </Box>
  );
};
