import { IconsTypesProps } from './types';
export const StreametricLogo = ({ height = 50, width = 219 }: IconsTypesProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 406.839 96.48"
  >
    <defs>
      <style type="text/css">
        @import
        url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
      </style>
      <clipPath id="clip-path">
        <path
          id="Path_2590"
          data-name="Path 2590"
          d="M46.57,0h.757c2.88.1,4.388,2.143,5.881,4.19a20.271,20.271,0,0,1,1.78,3c3.525,7.145,7.5,14.056,11.239,21.086,2.75,5.167,5.558,10.306,8.365,15.443,3.184,5.828,6.295,11.7,9.563,17.478a3.473,3.473,0,0,1,.284.6c.241.723.043,1.042-.709,1.208a17.918,17.918,0,0,1-4.142.2,5.829,5.829,0,0,0-2.725.628c-6.076,3-12.213,5.834-18.32,8.766Q51.436,76.01,44.291,79.35c-7.068,3.316-14.118,6.671-21.212,9.93-3.03,1.39-6.021,2.882-9.157,4.033a14.9,14.9,0,0,1-8.423.878,6.8,6.8,0,0,1-4.88-3.958A14.472,14.472,0,0,1,.04,87.017V83.989c.211-.347.091-.74.116-1.1a13.15,13.15,0,0,1,1.37-4.87C3.961,72.763,6.459,67.538,9.19,62.426,10.464,60.039,11.651,57.6,12.9,55.2c3.864-7.425,7.791-14.822,11.711-22.215q3.382-6.382,6.832-12.727c2.578-4.722,5.048-9.5,7.658-14.207A22.037,22.037,0,0,1,41.39,2.823,6.6,6.6,0,0,1,46.57,0Z"
          transform="translate(-0.04 0)"
          fill="#fff"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#04cc90" />
        <stop offset="1" stopColor="#049acc" />
      </linearGradient>
    </defs>
    <g id="Group_7880" data-name="Group 7880" transform="translate(-37.266 -35.978)">
      <g id="Group_2910" data-name="Group 2910" transform="translate(37.266 35.978)">
        <g id="Group_2905" data-name="Group 2905" transform="translate(52.935 72.44)">
          <path
            id="Path_2591"
            data-name="Path 2591"
            d="M498.808,637.963a12.8,12.8,0,0,1-4.653,9.568,9.35,9.35,0,0,1-6.231,2.084,3.385,3.385,0,0,1-1.183-.287q-14.457-5.569-28.91-11.142c-.248-.1-.664-.092-.661-.426,0-.375.43-.409.712-.515,6.416-2.437,12.842-4.85,19.25-7.31,3.467-1.331,6.969-2.581,10.321-4.2a2.594,2.594,0,0,1,1.576-.074c6.21.928,9.808,5.174,9.779,12.3Z"
            transform="translate(-457.17 -625.577)"
            fill="#049acc"
          />
        </g>
        <g
          id="Mask_Group_70"
          data-name="Mask Group 70"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_2592"
            data-name="Path 2592"
            d="M.5-363.91V-473.5H112.516v109.59Z"
            transform="translate(-10.006 465.002)"
            fill="url(#linear-gradient)"
          />
        </g>
      </g>
      <text
        id="STREAMETRIC"
        transform="translate(132.104 97.148)"
        fill="#05b6b2"
        fontSize="41"
        fontFamily="Montserrat"
        fontWeight="500"
        letterSpacing="0.03em"
      >
        <tspan x="0" y="0">
          STREAMETRIC
        </tspan>
      </text>
    </g>
  </svg>
);
