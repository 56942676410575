import {
  JSXElementConstructor,
  ReactElement,
  cloneElement,
  useLayoutEffect,
  useState,
} from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DoubleLeftArrowIcon, HamburgerIcon } from 'src/assets';
import {
  sideBarHeader,
  mainContent,
  mainContentMap,
  StyledPageContent,
  StyledMainTop,
  iconBox,
  StyledAppLayout,
  mapIconBox,
} from './style';
import { Header } from '../header/Header';
import { SideBar } from '../sideBar';
import { HeaderNavItemType, NavSection } from 'src/features';
import { useToggle, useResponsive } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router';
import { ROOT_PATH, SITES_MANAGEMENT_PATH, SITES_MAP_PATH } from 'src/routes/config';
import { useAuthentication } from 'src/features/authentication/context';
import { StreametricLogo } from 'src/assets/icons/StreametricLogo';
import { useCustomer } from 'src/contexts/customerContext/customer.context';
import { useCustomerAssets } from 'src/contexts/assetContext/asset.context';

type AppLayoutProps = {
  children: React.ReactNode;
  headerTitle?: string;
  headerNavItems: HeaderNavItemType[];
  isSuperAdmin?: boolean;
  isCustomerAccountPage?: boolean;
};

export const AppLayout = ({
  children,
  headerTitle,
  headerNavItems,
  isSuperAdmin,
  isCustomerAccountPage,
}: AppLayoutProps) => {
  const theme = useTheme();

  const whiteColor = theme.palette.common.white;
  const greyColorBold = theme.palette.text.secondary;
  const primaryMainColor = theme.palette.primary.main;
  const [logoUrl, setLogoUrl] = useState<string>('');

  const doubleLeftArrowIconColor = isSuperAdmin ? whiteColor : theme.palette.text.secondary;

  const isMobile = useResponsive('down', 'md');
  const { state, onToggle } = useToggle();
  const navigate = useNavigate();
  const {
    getCurrentRole,
    getAllCustomerIds,
    customerId: { value: customerId },
  } = useAuthentication();
  const { uniqueIds } = { ...getAllCustomerIds() };

  const validCustomerId = customerId ?? '';
  const { themeIds } = useCustomer(validCustomerId);
  const { customerAssets } = useCustomerAssets(themeIds?.activeThemeId, validCustomerId);
  const handleLogoClick = () => {
    if (!customerId || getCurrentRole(null, null) === 'SUPER_ADMIN') {
      navigate(ROOT_PATH);
      return;
    }

    navigate(SITES_MANAGEMENT_PATH + '?customerId=' + customerId);
  };
  const handleCloseSideBar = () => {
    if (state) onToggle();
  };
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.pathname === '/') {
      setLogoUrl('');
    } else if (customerAssets?.logo) {
      setLogoUrl(customerAssets?.logo);
    }
  }, [customerAssets?.logo, location.pathname]);

  const isSiteMap = location.pathname === SITES_MAP_PATH;
  const mainContentStyles = isSiteMap ? [mainContent, mainContentMap] : mainContent;
  const hederIconStyles = isSiteMap ? mapIconBox : iconBox;
  const showHeaderComponent = (isSiteMap && !isMobile) || !isSiteMap;
  const cloneChildren = isSiteMap && isMobile;

  const SiteHeader = () => (
    <StyledMainTop isTransparent={isMobile && isSiteMap} theme={theme} isMobile={isMobile}>
      {isMobile ? (
        <Box sx={hederIconStyles} onClick={onToggle} data-sm="side-bar-open">
          <HamburgerIcon fill={greyColorBold} />
        </Box>
      ) : null}
      <Header
        title={headerTitle}
        isTransparent={isMobile && isSiteMap}
        showButton={
          getCurrentRole(null, null) !== 'SUPER_ADMIN' && uniqueIds ? uniqueIds.length > 1 : false
        }
      />
    </StyledMainTop>
  );

  return (
    <StyledAppLayout>
      <SideBar
        isOpen={state}
        isMobile={isMobile}
        isSuperAdmin={isSuperAdmin}
        isCustomerAccountPage={isCustomerAccountPage}
      >
        <Box sx={sideBarHeader}>
          <Box onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
            {themeIds?.activeThemeId && logoUrl ? (
              <Box
                component="img"
                rel="preload"
                src={logoUrl}
                width={200}
                height={100}
                sx={{ border: 'none' }}
              />
            ) : (
              <Box
                width={200}
                height={100}
                sx={{ border: 'none', display: 'block', margin: 'auto', paddingTop: 3 }}
              >
                <StreametricLogo fill={primaryMainColor} />
              </Box>
            )}
          </Box>
          {isMobile ? (
            <Stack
              flexDirection={'column'}
              justifyContent={'center'}
              onClick={onToggle}
              data-sm="side-bar-close"
            >
              <DoubleLeftArrowIcon height={17} width={20} fill={doubleLeftArrowIconColor} />
            </Stack>
          ) : null}
        </Box>
        <NavSection
          navHeaderItems={headerNavItems}
          handleCloseSideBar={handleCloseSideBar}
          isSuperAdmin={isSuperAdmin}
          isCustomerAccountPage={isCustomerAccountPage}
        />
      </SideBar>

      <Box component="main" sx={mainContentStyles} onClick={handleCloseSideBar}>
        {showHeaderComponent ? <SiteHeader /> : null}
        <StyledPageContent
          theme={theme}
          isMobile={isMobile}
          component="section"
          noPadding={isSiteMap}
        >
          {cloneChildren
            ? cloneElement(children as ReactElement<any, string | JSXElementConstructor<any>>, {
                SiteHeader: SiteHeader,
              })
            : children}
        </StyledPageContent>
      </Box>
    </StyledAppLayout>
  );
};
