import { NewSite } from '../types/sites';

export const getAddressString = (row: NewSite | undefined) => {
  if (!row) return '';
  const street = row && row.location && row.location.street;
  const city = row && row.location && row.location.city;
  const state = row && row.location && row.location.state;
  const zip = row && row.location && row.location.zip;
  const country = row && row.location && row.location.country;
  const addressComponents = [street, city, state, zip, country].filter(Boolean);
  const address = addressComponents.join(', ');

  return address;
};
