import { IconsTypesProps } from '../types';
import { CURRENT_COLOR } from 'src/config';

export const MailIcon = ({ fill, width = 14, height = 12 }: IconsTypesProps) => {
  const color = fill ?? CURRENT_COLOR;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33203 0.666504H11.6654C12.7699 0.666504 13.6654 1.56193 13.6654 2.6665V9.33317C13.6654 10.4377 12.7699 11.3332 11.6654 11.3332H2.33203C1.22746 11.3332 0.332031 10.4377 0.332031 9.33317V2.6665C0.332031 1.56193 1.22746 0.666504 2.33203 0.666504ZM7.33203 4.97984L11.6654 1.99984H2.33203L6.66536 4.97984C6.87163 5.09893 7.12576 5.09893 7.33203 4.97984Z"
        fill={color}
      />
    </svg>
  );
};
