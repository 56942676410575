import { Box, Typography, useTheme } from '@mui/material';
import { GetAllResponse } from 'src/services/sites/types';
import { CloseIcon } from 'src/assets';
import { StyledSelectedItemLabel, labelTitle, closeIconBox } from './style';
import { SEPARATOR } from '../../config';

type SelectedItemLabelTypes = {
  selectedValue: any;
  sites?: GetAllResponse[];
  setValue: (name: string, value: string) => void;
};

export const SelectedItemLabel = ({ selectedValue, sites, setValue }: SelectedItemLabelTypes) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[600];

  const value = selectedValue[0];
  const isTagElement = value.includes('row');
  const isEventElement = value.includes('events');
  const siteId = value.split(SEPARATOR)[1];

  const findSite = sites?.find((site) => site.id === siteId);

  const getTitle = () => {
    const info = value.split(SEPARATOR);

    if (isTagElement) {
      return info[info.length - 1].replace(/\(dot\)/g, '.');
    } else if (isEventElement) {
      return info[0];
    }
  };

  const onDeleteHandler = () => {
    setValue(selectedValue[0], 'false');
  };

  return (
    <StyledSelectedItemLabel>
      <Typography sx={labelTitle}>
        {findSite?.siteName} {findSite ? '-' : ''} {getTitle()}
      </Typography>
      <Box sx={closeIconBox} onClick={onDeleteHandler}>
        <CloseIcon width={10} fill={greyColor} />
      </Box>
    </StyledSelectedItemLabel>
  );
};
