import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, CONTROLLERS_EDIT_HEADER, LOGIN_PATH } from '../../config';
import { AppLayout } from 'src/layouts';
import { NAV_ITEMS_HEADER_SELECTED_SITE, getNavItemsHeaderSelectedSiteSAAndAA } from 'src/features';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { EditControllerPage } from 'src/routes/pages';
import { AccessRoles } from 'src/features/user-account-details/types';

export const ControllerEditElement = ({ isSuperAdmin }: ElementProps) => {
  const { loggedIn, customerId, siteId, siteIdBelongsToCustomer, getCurrentRole, user } =
    useAuthentication();

  const isAccountAdmin =
    getCurrentRole(customerId.value, siteId.value) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!siteIdBelongsToCustomer.value) return ACCOUNT_ISSUE_PATH + `?reason=Site does not exist`;
  };

  const ControllerEditRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={siteIdBelongsToCustomer.value}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        headerTitle={CONTROLLERS_EDIT_HEADER}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSelectedSiteSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SELECTED_SITE
        }
      >
        <EditControllerPage />
      </AppLayout>
    </Protected>
  );

  const getControllerEditElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return <ControllerEditRouteElement />;

    if (!customerId.value || !customerId.loaded || !siteId.value || !siteId.loaded)
      return <LoadingElement />;

    if (!siteIdBelongsToCustomer.loaded) return <LoadingElement />;

    if ((isSuperAdmin || isAccountAdmin) && loggedIn.loaded) return <ControllerEditRouteElement />;

    return <ControllerEditRouteElement />;
  };

  return getControllerEditElement();
};
