import Router from './routes';
import { useTheme } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { customScrollbar } from './styles';

export default function App() {
  const theme = useTheme();
  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  });

  useEffect(() => {
    const styleElement = document.createElement('style');
    const selectors = ['body', '.custom-autocomplete-scrollbar'];
    selectors.forEach((selector) =>
      styleElement.appendChild(document.createTextNode(customScrollbar(selector, theme)))
    );
    document.head.appendChild(styleElement);
  }, [theme]);

  return <Router />;
}
