import { useState, useEffect } from 'react';
import { ReadingsService } from 'src/services';

type UseGetLatestGatewayReadingsArgs = {
  gatewayId?: string;
  tagId?: string;
};

export const useGetLatestGatewayReadings = ({
  gatewayId,
  tagId,
}: UseGetLatestGatewayReadingsArgs) => {
  const [readings, setReadings] = useState<any>([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  useEffect(() => {
    if (!gatewayId || !tagId) return;

    ReadingsService.getLatestReadingsNewDataStorage({
      gatewayId: gatewayId,
      measurements: tagId,
    })
      .then((res) => {
        setReadings(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDataReady(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId, gatewayId]);

  return { readings, isDataReady };
};
