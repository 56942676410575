export const validateValueChange = (
  value: string,
  allowNegative: boolean = true,
  maximumDecimalPoints: number = -1
) => {
  if (value === null || value === undefined) {
    return;
  }

  if (value === '-0' || value === '0-') value = '0';

  // If the value starts with a decimal point, remove it
  if (value.startsWith('.')) {
    value = value.slice(1);
  }

  // Allow only one dot and ignore the rest
  const dotCount = value.split('.').length - 1;
  if (dotCount > 1) {
    const parts = value.split('.');
    value = parts[0] + '.' + parts.slice(1).join('');
  }

  // Remove any characters other than numbers, minus, and dot
  let newValue =
    maximumDecimalPoints === 0
      ? value.replace(/[^0-9-.]/g, '').split('.')?.[0]
      : value.replace(/[^0-9\-.]/g, '');

  // Ensure there's only one minus sign at the beginning (for negative numbers)
  if (allowNegative) {
    const minusCount = newValue.split('-').length - 1;
    if (minusCount > 0 && minusCount % 2 === 0) {
      newValue = newValue.replace(/-/g, '');
    } else if (minusCount > 0 && minusCount % 2 !== 0) {
      newValue = '-' + newValue.replace(/-/g, '');
    }
  } else {
    newValue = newValue.replace(/-/g, '');
  }

  // Limit the decimal part to the specified maximumDecimalPoints (-1 = unlimited decimal points)
  if (maximumDecimalPoints === -1) return newValue;

  const parts = newValue.split('.');
  if (parts.length > 1) {
    const integerPart = parts[0];
    const decimalPart = parts[1].slice(0, maximumDecimalPoints);
    newValue = `${integerPart}.${decimalPart}`;
  }

  // Remove leading zeros, but keep "0." intact for decimal numbers
  newValue = newValue.replace(/^(-?)0+(0\.|\d)/, '$1$2');

  return newValue;
};
