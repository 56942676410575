import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFAutocomplete, RHFCheckbox } from 'src/components/minimals/form';
import { filterOptions } from 'src/utilities';

type ReportsNewFilterType = {
  open: boolean;
  handleFilterClose: VoidFunction;
};

type FilterData = {
  userId: string | null;
  favorites: boolean;
};

export const ReportsNewFilter = ({ open, handleFilterClose }: ReportsNewFilterType) => {
  const theme = useTheme();

  const formValues: FilterData = {
    userId: null,
    favorites: false,
  };

  const methods = useForm<FilterData>({
    defaultValues: formValues,
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const DrawerRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleFilterSubmit = (data: FilterData) => {
    // TO DO: Call filter endpoint here and format filter data state if needed.
    console.log(data);
    handleFilterClose();
  };

  const handleFilterClear = () => {
    reset();
  };

  const handleUserChange = (newUserId: string | null) => {
    setValue('userId', newUserId);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit((data) => handleFilterSubmit(data))}>
      <Drawer
        sx={{
          width: 320,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 320,
            backgroundColor: theme.palette.background.default,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Stack sx={{ flexDirection: 'column', height: '100%' }}>
          <Stack sx={{ height: '100%' }}>
            <DrawerRow>
              <Typography variant="h5">Filters</Typography>
            </DrawerRow>
            <Divider />
            <DrawerRow sx={{ paddingBottom: '0px' }}>
              <Typography variant="h6">Created By</Typography>
            </DrawerRow>
            <DrawerRow sx={{ paddingTop: '0px' }}>
              <RHFAutocomplete
                name="userId"
                size="small"
                fullWidth
                options={['User 1', 'User 2', 'User 3', 'User 4']}
                onChange={(_, newValue) => {
                  handleUserChange(newValue as string | null);
                }}
                filterOptions={(options: string[], state: any) =>
                  filterOptions(options, state, options)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a user"
                    inputProps={{
                      ...params.inputProps,
                      'data-sm': `created-by-user-input`,
                    }}
                  />
                )}
              />
            </DrawerRow>
            <Divider />
            <DrawerRow>
              <RHFCheckbox name="favorites" label="Favorites" />
            </DrawerRow>
          </Stack>
          <Stack sx={{ height: '100%', justifyContent: 'flex-end' }}>
            <DrawerRow>
              <Stack
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around !important',
                }}
              >
                <Button
                  sx={{ flex: 1 }}
                  variant="outlined"
                  color="error"
                  onClick={handleFilterClear}
                >
                  Clear All
                </Button>
                <LoadingButton
                  sx={{ flex: 1, ml: '8px' }}
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Apply
                </LoadingButton>
              </Stack>
            </DrawerRow>
          </Stack>
        </Stack>
      </Drawer>
    </FormProvider>
  );
};
