import { MenuItem, Box } from '@mui/material';
import { MenuItemsProps } from './types';

export const MenuPopoverItem = ({
  icon,
  customContent,
  color,
  testId,
  handleClick,
  sx,
  ...other
}: MenuItemsProps) => {
  const colorStyle = color || 'inherit';

  return (
    <MenuItem
      onClick={() => handleClick()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: colorStyle,
        ...sx,
      }}
    >
      {icon}
      <Box data-sm={testId}>{customContent}</Box>
    </MenuItem>
  );
};
