import { IconsTypesProps } from './types';

export const PumpIcon = ({ width = 100, height = 82 }: IconsTypesProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 96 80"
    fill="none"
  >
    <path
      d="M9.45212 48.6083L7.73177 48.6083C7.41711 48.6083 7.16203 48.3532 7.16203 48.0386V48.0386C7.16203 47.7239 6.90695 47.4688 6.59229 47.4688L5.20594 47.4688C4.12563 47.4688 3.24987 48.3446 3.24987 49.4249L3.24988 52.7166L3.24991 54.8637C3.24993 55.944 4.12568 56.8198 5.20597 56.8198L6.6848 56.8198C6.94837 56.8198 7.16203 56.6061 7.16203 56.3425V56.3425C7.16203 56.079 7.37569 55.8653 7.63925 55.8653L27.1019 55.8653C27.6303 55.8653 28.0586 55.437 28.0586 54.9086V54.9086C28.0586 54.3802 28.487 53.9519 29.0153 53.9519L37.6032 53.9518C39.8151 53.9517 41.6082 52.1586 41.6082 49.9467V49.9467C41.6082 47.7348 43.4013 45.9417 45.6132 45.9417L47.1901 45.9417C48.9028 45.9417 50.2912 47.3301 50.2912 49.0428V49.0428C50.2912 50.7555 51.6796 52.1439 53.3923 52.1439L69.2343 52.1439C69.7335 52.1439 70.1381 52.5486 70.1381 53.0478V53.0478C70.138 53.547 70.5427 53.9517 71.0419 53.9517L83.8402 53.9517C86.6016 53.9517 88.8402 51.7131 88.8402 48.9517L88.8402 32.7165C88.8402 29.955 86.6016 27.7164 83.8402 27.7165L70.663 27.7165C70.0043 27.7165 69.4703 28.2505 69.4703 28.9092V28.9092C69.4703 29.5679 68.9363 30.1019 68.2776 30.1019L53.4877 30.1019C51.7223 30.1019 50.2912 31.5331 50.2912 33.2984V33.2984C50.2912 35.0638 48.8601 36.4949 47.0947 36.4949L45.4253 36.4949C43.3172 36.4949 41.6082 34.786 41.6082 32.6778V32.6778C41.6082 30.5697 39.8992 28.8607 37.7911 28.8607L15.4752 28.8606C12.7138 28.8606 10.4752 31.0991 10.4752 33.8606L10.4752 43.6083C10.4752 46.3697 12.7138 48.6083 15.4752 48.6083L17.429 48.6083C20.1904 48.6083 22.429 46.3697 22.429 43.6083L22.429 40.7895C22.429 40.7895 22.429 40.7895 22.429 40.7895C22.429 40.7895 22.429 37.3545 26.1503 37.3545C29.6808 37.3545 29.6808 40.7895 29.6808 40.7895C29.6808 40.7895 29.6808 40.7895 29.6808 40.7895L29.6808 52.7166"
      stroke="#C2C6C8"
    />
    <line x1="70.3518" y1="31.7241" x2="70.3518" y2="50.2353" stroke="#C2C6C8" />
    <line x1="69.8518" y1="33.5601" x2="56.3024" y2="33.5601" stroke="#C2C6C8" />
    <line x1="69.8518" y1="35.8506" x2="56.3024" y2="35.8506" stroke="#C2C6C8" />
    <line x1="69.8518" y1="38.1406" x2="56.3024" y2="38.1406" stroke="#C2C6C8" />
    <line x1="69.8518" y1="40.4302" x2="56.3024" y2="40.4302" stroke="#C2C6C8" />
    <line x1="69.8518" y1="42.7207" x2="56.3024" y2="42.7207" stroke="#C2C6C8" />
    <line x1="17.2532" y1="55.98" x2="16.1082" y2="58.4609" stroke="#C2C6C8" />
    <line x1="13.6733" y1="58.251" x2="13.6733" y2="61.686" stroke="#C2C6C8" />
    <line x1="22.8335" y1="26.5718" x2="22.8335" y2="28.8618" stroke="#C2C6C8" />
    <line x1="29.5128" y1="26.5718" x2="29.5128" y2="28.8618" stroke="#C2C6C8" />
    <line x1="22.8335" y1="12.8325" x2="22.8335" y2="23.7102" stroke="#C2C6C8" />
    <line x1="29.5128" y1="12.8325" x2="29.5128" y2="23.7102" stroke="#C2C6C8" />
    <line x1="22.8336" y1="3.8623" x2="22.8336" y2="9.96907" stroke="#C2C6C8" />
    <line x1="29.5128" y1="3.8623" x2="29.5128" y2="9.96907" stroke="#C2C6C8" />
    <line x1="85.2371" y1="58.251" x2="85.2371" y2="61.686" stroke="#C2C6C8" />
    <line x1="84.7371" y1="58.751" x2="13.3642" y2="58.751" stroke="#C2C6C8" />
    <line x1="89.6988" y1="62.1865" x2="8.40249" y2="62.1865" stroke="#C2C6C8" />
    <line x1="34.6044" y1="53.8128" x2="36.8944" y2="58.0112" stroke="#C2C6C8" />
    <line x1="69.8518" y1="45.0103" x2="56.3024" y2="45.0103" stroke="#C2C6C8" />
    <line x1="69.8518" y1="47.3008" x2="56.3024" y2="47.3008" stroke="#C2C6C8" />
    <line x1="69.8518" y1="49.5908" x2="56.3024" y2="49.5908" stroke="#C2C6C8" />
    <rect x="76.5311" y="31.5332" width="7.06095" height="3.24422" rx="1.62211" stroke="#C2C6C8" />
    <rect x="21.1885" y="23.71" width="9.35099" height="2.86255" rx="1.43127" stroke="#C2C6C8" />
    <rect x="21.1885" y="9.96924" width="9.35099" height="2.86255" rx="1.43127" stroke="#C2C6C8" />
    <rect x="21.1885" y="1" width="9.35099" height="2.86255" rx="1.43127" stroke="#C2C6C8" />
  </svg>
);

export default PumpIcon;
