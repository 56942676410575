import React, { useState, useContext, useEffect, useMemo } from 'react';
import { CustomerAccount } from 'src/features/customer-accounts/types/customer-account';
import { CustomerService } from 'src/services';
import { CustomerProviderPropsTypes, CustomerContextTypes, CustomerThemeIds } from './types';

const CustomerContext = React.createContext<CustomerContextTypes>({} as CustomerContextTypes);

export const useCustomer = (customerId?: string) => {
  const context = useContext(CustomerContext);

  useEffect(() => {
    if (customerId) {
      context.setCustomerId(customerId);
    }
  }, [customerId, context]);

  return context;
};

export const CustomerProvider = ({ children }: CustomerProviderPropsTypes) => {
  const [customerName, setCustomerName] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const [customerId, setCustomerId] = useState<string | null>(null);
  const [themeIds, setThemeIds] = useState<CustomerThemeIds>({
    activeThemeId: ' ',
    inactiveThemeIds: [],
  });

  useEffect(() => {
    setLoading(true);
    if (customerId) {
      CustomerService.getById(customerId)
        .then((data: CustomerAccount) => {
          setCustomerName(data.customerName);
          setThemeIds({
            activeThemeId: data.selectedThemeId,
            inactiveThemeIds: data.inactiveThemeIds,
          });
          setLoading(false);
        })
        .catch((error) => {
          setCustomerName('');
          setThemeIds({ activeThemeId: ' ', inactiveThemeIds: [] });
          setLoading(false);
        });
    } else {
      setCustomerName('');
      setThemeIds({ activeThemeId: ' ', inactiveThemeIds: [] });
      setLoading(false);
    }
  }, [customerId]);
  const contextValue = useMemo(
    () => ({
      setCustomerId,
      customerName,
      loading,
      themeIds,
    }),
    [setCustomerId, customerName, loading, themeIds]
  );

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>;
};
