import { useEffect, useRef, useState } from 'react';

type UseChartOptionsType = {
  fromDate: Date;
  toDate: Date;
  debouncedExtendTimeframeOnZoomOutOrPan: (min: string, max: string) => void;
};

export const useChartOptions = ({
  fromDate,
  toDate,
  debouncedExtendTimeframeOnZoomOutOrPan,
}: UseChartOptionsType) => {
  const [isPanAndZoomActive, setIsPanAndZoomActive] = useState<boolean>(true);
  const chartRef: any = useRef(null);

  const changeTimeFormatHandler = (from: Date, to: Date) => {
    const chartInstance = chartRef?.current;

    setOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        scales: {
          x: {
            type: 'time',
            min: from.toISOString(),
            max: to.toISOString(),
            ticks: {
              maxTicksLimit: 12,
              source: 'auto',
            },
            time: {
              displayFormats: {
                day: 'MMM dd, yyyy h:mm a',
                hour: 'MMM dd, yyyy h:mm a',
              },
            },
          },
        },
      };
      chartInstance.options = newOptions;
      chartInstance.update();
      return newOptions;
    });
  };

  const [options, setOptions] = useState({
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
      axis: 'xy',
    },
    plugins: {
      crosshair: {
        line: {
          color: '#919EAB',
          width: 1,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: false,
          mode: 'xy',
          zoomboxBackgroundColor: 'rgba(0, 0, 0, 0.1)', // Set a light transparent color
          zoomboxBorderColor: 'rgba(0, 0, 0, 0.5)', // Set a border color for visibility
          zoomButtonText: null,
          zoomButtonClass: null,
        },
        callbacks: {
          beforeZoom: () => function (start: any, end: any) {},
          afterZoom: () => function (start: any, end: any) {},
        },
      },
      legend: {
        position: 'top',
        align: 'start',
        display: true,

        labels: {
          boxWidth: 6,
          boxHeight: 7,
          pointStyle: 'circle',
          usePointStyle: true,
          useBorderRadius: true,
          padding: 10,
          lineHeight: 1.2,
        },
      },
      zoom: {
        pan: {
          enabled: isPanAndZoomActive,
          mode: 'xy',
          speed: 10,
          onPan: ({ chart }: { chart: any }) => {
            if (chartRef) {
              const { min, max } = chart.scales.x;
              debouncedExtendTimeframeOnZoomOutOrPan(min, max);
            }
          },
        },
        zoom: {
          wheel: {
            enabled: isPanAndZoomActive,
          },
          drag: {
            enabled: !isPanAndZoomActive,
            mode: 'xy',
          },
          pinch: {
            enabled: isPanAndZoomActive,
          },
          mode: 'xy',
          speed: 10,
          onZoom: ({ chart }: { chart: any }) => {
            const { min, max } = chart.scales.x;
            debouncedExtendTimeframeOnZoomOutOrPan(min, max);
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        min: fromDate.toISOString(),
        max: toDate.toISOString(),
        ticks: {
          maxTicksLimit: 12,
          source: 'auto',
        },
        time: {
          displayFormats: {
            day: 'MMM dd, yyyy h:mm a',
            hour: 'MMM dd, yyyy h:mm a',
          },
        },
      },
    },
  });

  useEffect(() => {
    const chartInstance = chartRef?.current;
    const zoomLevel = chartInstance?.getZoomLevel();
    setOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        plugins: {
          ...prevOptions.plugins,
          zoom: {
            ...prevOptions.plugins.zoom,
            pan: {
              ...prevOptions.plugins.zoom.pan,
              enabled: false,
            },
            zoom: {
              ...prevOptions.plugins.zoom.zoom,
              wheel: {
                ...prevOptions.plugins.zoom.zoom.wheel,
                enabled: false,
              },
              drag: {
                enabled: true,
                mode: 'xy',
              },
              pinch: {
                ...prevOptions.plugins.zoom.zoom.pinch,
                enabled: false,
              },
              level: zoomLevel,
            },
          },
        },
      };
      chartInstance.options = newOptions;
      chartInstance.update();
      return newOptions;
    });

    setIsPanAndZoomActive(false);
  }, []);

  const togglePanAndZoom = () => {
    const chartInstance = chartRef?.current;
    const zoomLevel = chartInstance?.getZoomLevel();

    setIsPanAndZoomActive((prevState) => {
      const newState = !prevState;

      if (chartRef && chartRef.current) {
        chartRef.current.canvas.style.touchAction = newState ? 'none' : 'unset';
      }

      return newState;
    });

    setOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        plugins: {
          ...prevOptions.plugins,
          zoom: {
            ...prevOptions.plugins.zoom,
            pan: {
              ...prevOptions.plugins.zoom.pan,
              enabled: !prevOptions.plugins.zoom.pan.enabled,
            },
            zoom: {
              ...prevOptions.plugins.zoom.zoom,
              wheel: {
                ...prevOptions.plugins.zoom.zoom.wheel,
                enabled: !prevOptions.plugins.zoom.zoom.wheel.enabled,
              },
              drag: {
                ...prevOptions.plugins.zoom.pan,
                enabled: !prevOptions.plugins.zoom.zoom.drag.enabled,
              },
              pinch: {
                ...prevOptions.plugins.zoom.zoom.pinch,
                enabled: !prevOptions.plugins.zoom.zoom.pinch.enabled,
              },
              level: zoomLevel,
            },
          },
        },
      };
      chartInstance.options = newOptions;
      chartInstance.update();
      return newOptions;
    });
  };

  const onResetZoomHandler = () => {
    if (chartRef) {
      chartRef.current.resetZoom();
    }
  };

  const zoomInHandler = () => {
    if (chartRef) {
      chartRef.current.zoom(1.2);
    }
  };

  const zoomOutHandler = () => {
    if (chartRef) {
      chartRef.current.zoom(0.8);
      // const min = chartRef.current.scales.x.min;
      // const max = chartRef.current.scales.x.max;
      // debouncedExtendTimeframeOnZoomOutOrPan(min, max);
    }
  };

  return {
    chartRef,
    onResetZoomHandler,
    zoomInHandler,
    zoomOutHandler,
    isPanAndZoomActive,
    togglePanAndZoom,
    options,
    changeTimeFormatHandler,
  };
};
