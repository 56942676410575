import { TableCell, Typography, Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AlarmWarningIcon } from 'src/assets';
import {
  StyledTableRow,
  tableCellBox,
  iconSiteBox,
  noLiveDataTableCell,
  iconAlarmBox,
  StyledTableDirectionsCell,
  StyledTableSiteCell,
  StyledTableAlarmCell,
  StyledTableSiteCellBox,
} from './style';
import { NewSite } from 'src/features/sites/types/sites';
import { SitesService } from 'src/services/sites';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { SiteInterface } from 'src/features/sites/types/site';
import { useNavigate } from 'react-router';
import { SITES_DASHBOARD_PATH, SITES_MANAGEMENT_PATH } from 'src/routes/config';
import { useAuthentication } from 'src/features/authentication/context';
import { WarningIcon, InfoIcon } from 'src/features/theme/overrides/CustomIcons';
import { AlarmPriorityType } from 'src/features/alarm-rules/types';
import { GetDirectionsButton } from '../../get-directions-button/GetDirectionsButton';
import { NoLiveDataSiteCard } from '../../no-live-data-site-card';
import { SiteTypeIcon } from '../../site-type-icon';
import { TagsList } from '../../tags-list';
import { TagsTypes } from 'src/features/sites/types/types';
import { useEffect, useState } from 'react';

type TableRowItemTypes = {
  row?: NewSite;
  selected?: boolean;
  testId?: string;
  onSelectRow: VoidFunction;
  tags: TagsTypes;
  liveData?: any;
  handleEditSitePreview: (siteId: string | null) => void;
  roleAccessInfoBox: boolean;
};

export const TableRowItem = ({
  row,
  selected,
  onSelectRow,
  tags,
  handleEditSitePreview,
  liveData,
  roleAccessInfoBox,
}: TableRowItemTypes) => {
  const { saveSite } = useSite();
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const navigate = useNavigate();
  const theme = useTheme();

  const noDataAndShowDataTrue = !tags?.tags?.length && tags?.showLiveData === true;
  const displayNoLiveDataLabel = !tags || noDataAndShowDataTrue;

  const [noLiveDataLabel, setNoLiveDataLabel] = useState<boolean>(displayNoLiveDataLabel);

  useEffect(() => {
    setNoLiveDataLabel(displayNoLiveDataLabel);
  }, [displayNoLiveDataLabel]);

  const liveDateForSite = liveData?.filter((item: any) => item.site_id === row?.id) || [];

  const combinedGatewayInputs = liveDateForSite.reduce(
    (acc: any, item: any) => acc.concat(item.gateway_inputs),
    []
  );

  const liveDataComplete = {
    siteId: liveDateForSite?.site_id,
    tags: liveDateForSite && liveDateForSite.length > 0 ? combinedGatewayInputs : undefined,
  };

  const removeNoLiveDataLabelHandler = () => {
    setNoLiveDataLabel(false);
  };

  const lengtOfConnectedTags = tags?.tags?.length;

  const handleRedirect = (siteId: string) => {
    if (!customerId) return;
    navigate(SITES_DASHBOARD_PATH + `?customerId=${customerId}&siteId=${siteId}`);
  };

  const handleRowClick = () => {
    if (!customerId) return;
    SitesService.getById({ customerId: customerId, siteId: row!.id })
      .then((data: SiteInterface) => {
        saveSite(data);
      })
      .catch((error) => console.error(error));
    handleRedirect(row!.id);
    onSelectRow();
  };

  const handleAlarmView = (priority: AlarmPriorityType) => {
    switch (priority) {
      case 'Critical':
        return <AlarmWarningIcon fill={theme.palette.error.main} />;
      case 'Warning':
        return <WarningIcon htmlColor={theme.palette.warning.main} />;
      case 'Info':
        return <InfoIcon htmlColor={theme.palette.secondary.main} />;
      default:
        return '-';
    }
  };

  const onMiddleMouseClick = (event: any) => {
    event.preventDefault();
    if (event.button === 1) {
      const currentLocation = window.location.href;
      let newLocation = '';
      if (currentLocation.includes('siteId')) {
        const locationSplit = currentLocation.split('siteId');
        newLocation =
          locationSplit[0].replace(SITES_MANAGEMENT_PATH, SITES_DASHBOARD_PATH) +
          `siteId=${row?.id}`;
      } else {
        newLocation =
          currentLocation.replace(SITES_MANAGEMENT_PATH, SITES_DASHBOARD_PATH) +
          `&siteId=${row?.id}`;
      }

      window.open(newLocation, '_blank', 'noopener');
      window.blur();
    }
  };

  const isTopPositionCell = lengtOfConnectedTags > 4;
  const siteName = row?.siteName;

  return (
    <>
      <StyledTableRow
        theme={theme}
        hover
        selected={selected}
        data-sm="table-row"
        onMouseDown={onMiddleMouseClick}
        onClick={!lengtOfConnectedTags ? handleRowClick : () => {}}
        lengtOfConnectedTags={!!lengtOfConnectedTags}
      >
        <StyledTableSiteCell
          data-sm="site-name-row"
          isTopPosition={isTopPositionCell}
          onClick={handleRowClick}
        >
          <StyledTableSiteCellBox isTags={!!lengtOfConnectedTags}>
            <Box sx={iconSiteBox}>
              <SiteTypeIcon siteType={row?.siteType!!} />
            </Box>
            {lengtOfConnectedTags && siteName && siteName.length > 55 ? (
              <Tooltip title={siteName}>
                <Typography sx={{ textAlign: 'left' }} variant="subtitle2">
                  {siteName?.substring(0, 55)} ...
                </Typography>
              </Tooltip>
            ) : (
              <Typography sx={{ textAlign: 'left' }} variant="subtitle2">
                {siteName}
              </Typography>
            )}
          </StyledTableSiteCellBox>
        </StyledTableSiteCell>
        {noLiveDataLabel && roleAccessInfoBox ? (
          <TableCell sx={noLiveDataTableCell}>
            <NoLiveDataSiteCard
              testId="no-live-data-site-card"
              handleEditSitePreview={handleEditSitePreview}
              siteId={row?.id ?? null}
              customerId={customerId}
              removeNoLiveDataLabelHandler={removeNoLiveDataLabelHandler}
            />
          </TableCell>
        ) : (
          <TableCell>
            <TagsList
              isMobile={false}
              tags={tags}
              testId="tags-list"
              liveDataComplete={liveDataComplete}
            />
          </TableCell>
        )}
        <StyledTableAlarmCell data-sm="open-issues-row" isTopPosition={isTopPositionCell}>
          <Box sx={tableCellBox}>
            <Box sx={iconAlarmBox}>{handleAlarmView(row?.highestActiveAlarm!)}</Box>
          </Box>
        </StyledTableAlarmCell>
        <StyledTableDirectionsCell isTopPosition={isTopPositionCell}>
          <GetDirectionsButton row={row} handleEditSitePreview={handleEditSitePreview} />
        </StyledTableDirectionsCell>
      </StyledTableRow>
    </>
  );
};
