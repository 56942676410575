import { IconsTypesProps } from 'src/assets/icons/types';

export type AlarmType = {
  id: string;
  alarmTagName: string;
  assignedAlarmGatewayId: string | null;
  assignedAlarmTagId: null;
  level: number;
};

export enum AlarmKind {
  LOW,
  HIGH,
}

export type ChartData = {
  label: string;
  value: number;
};

export type ActiveModeType = {
  gatewayId?: string;
  index?: number;
  value?: number;
  category?: string;
  name?: string;
  tagId?: string;
  valueFlag?: any;
};

export type SubTypeWidget = {
  id: string;
  icon: React.FunctionComponent<IconsTypesProps>;
  name: string;
};
